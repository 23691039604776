<template>
  <!-- Base Style For Components Page -->
  <the-base-style>
    <template v-slot:background>
      <div class="background_industry"></div>
    </template>
    <template v-slot:base_header>
      <h2>Индустриальная область</h2>
    </template>
    <template v-slot:base_list>
      <!-- Card for Production of polyester fibers -->
      <the-base-card-style>
        <template v-slot:base_item></template>
        <template v-slot:base_icon>
          <!-- SVG Icon for Polyester Fibers -->
          <svg
            class="svg_image"
            xmlns="http://www.w3.org/2000/svg"
            xml:space="preserve"
            width="18.75mm"
            height="18.75mm"
            version="1.1"
            style="
              shape-rendering: geometricPrecision;
              text-rendering: geometricPrecision;
              image-rendering: optimizeQuality;
              fill-rule: evenodd;
              clip-rule: evenodd;
            "
            viewBox="0 0 9674.26 9674.26"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xmlns:xodm="http://www.corel.com/coreldraw/odm/2003"
          >
            <g id="Слой_x0020_1">
              <metadata id="CorelCorpID_0Corel-Layer" />
              <g id="_2632758424144">
                <g>
                  <path
                    class="polyesterFiberfil0"
                    d="M4837.13 546.04c2366.09,0 4291.09,1924.94 4291.09,4291.09 0,2366.09 -1924.94,4291.09 -4291.09,4291.09 -2366.09,0 -4291.09,-1924.94 -4291.09,-4291.09 0,-2366.14 1924.94,-4291.09 4291.09,-4291.09l0 0zm0 -546.04c-2671.49,0 -4837.13,2165.64 -4837.13,4837.13 0,2671.49 2165.64,4837.13 4837.13,4837.13 2671.49,0 4837.13,-2165.64 4837.13,-4837.13 0,-2671.49 -2165.69,-4837.13 -4837.13,-4837.13l0 0z"
                  />
                </g>
              </g>
              <g id="_2632758423184">
                <path
                  class="polyesterFiberfil0"
                  d="M5719.99 3301.99l-1777.48 0 -888.95 1539.52 888.95 1539.52 1777.48 0 888.95 -1539.52 -888.95 -1539.52zm-1636.16 176.56l1494.84 0c37.72,0 68.26,30.54 68.26,68.26 0,37.72 -30.54,68.26 -68.26,68.26l-1494.84 0c-37.72,0 -68.26,-30.54 -68.26,-68.26 0,-37.72 30.54,-68.26 68.26,-68.26zm34.1 2716.79c-10.78,6.19 -22.55,9.13 -34.1,9.13 -23.48,0 -46.54,-12.18 -59.08,-34.1l-747.47 -1294.75c-18.94,-32.66 -7.74,-74.4 24.92,-93.23 32.51,-18.88 74.4,-7.84 93.18,24.97l747.47 1294.7c18.94,32.66 7.74,74.4 -24.92,93.29zm1519.81 -25.02c-12.54,21.93 -35.6,34.1 -59.08,34.1 -11.61,0 -23.32,-2.94 -34.1,-9.13 -32.66,-18.88 -43.86,-60.57 -24.92,-93.23l747.47 -1294.7c18.78,-32.82 60.52,-43.65 93.18,-24.97 32.66,18.88 43.86,60.57 24.92,93.23l-747.47 1294.7z"
                />
                <path
                  class="polyesterFiberfil0"
                  d="M5798.93 3165.47l-1935.37 0 -967.58 1676.05 967.58 1676.05 1935.37 0 967.58 -1676.05 -967.58 -1676.05zm-39.42 3283.83l-1856.53 0 -928.26 -1607.78 928.26 -1607.78 1856.53 0.05 928.26 1607.78 -928.26 1607.73z"
                />
                <path
                  class="polyesterFiberfil0"
                  d="M5759.51 3233.78l-1856.53 0 -928.26 1607.78 928.26 1607.78 1856.53 -0.05 928.26 -1607.78 -928.26 -1607.73zm-39.52 3147.26l-1777.48 0 -888.95 -1539.52 888.95 -1539.52 1777.48 0 888.95 1539.52 -888.95 1539.52z"
                />
              </g>
              <line
                class="polyesterFiberfil1 polyesterFiberstr0"
                x1="6687.72"
                y1="4841.51"
                x2="7329.06"
                y2="4837.13"
              />
              <line
                class="polyesterFiberfil1 polyesterFiberstr0"
                x1="8278.02"
                y1="3233.78"
                x2="7329.06"
                y2="4837.13"
              />
              <line
                class="polyesterFiberfil1 polyesterFiberstr0"
                x1="7956.99"
                y1="3197.3"
                x2="7008.03"
                y2="4800.65"
              />
              <line
                class="polyesterFiberfil1 polyesterFiberstr0"
                x1="7155.44"
                y1="4862.1"
                x2="8104.45"
                y2="6465.45"
              />
              <line
                class="polyesterFiberfil1 polyesterFiberstr0"
                x1="2927.87"
                y1="4841.51"
                x2="2286.53"
                y2="4837.13"
              />
              <line
                class="polyesterFiberfil1 polyesterFiberstr0"
                x1="1337.58"
                y1="3233.78"
                x2="2286.53"
                y2="4837.13"
              />
              <line
                class="polyesterFiberfil1 polyesterFiberstr0"
                x1="1658.61"
                y1="3197.3"
                x2="2607.56"
                y2="4800.65"
              />
              <line
                class="polyesterFiberil1 polyesterFiberstr0"
                x1="2460.15"
                y1="4862.1"
                x2="1511.2"
                y2="6465.45"
              />
            </g>
          </svg>
        </template>
        <template v-slot:base_title_active
          ><router-link
            v-on:click="scrollToTop"
            to="/productsForPolyesterFibers"
          >
            Производство полиэфирных волокон
          </router-link></template
        >
        <template v-slot:base_description>
          <div class="base_element">
            <li>Avifil PE 32</li>
          </div>
          <div class="base_element">
            <li>Avifil HT 906</li>
          </div>
          <div class="base_element">
            <li>Avifil PE 17</li>
          </div>
          <div class="base_element">
            <li>Avifil GEN</li>
          </div>
          <div class="base_element">
            <li>Novafil NPC</li>
          </div>
          <div class="base_element">
            <li>Пентаэритритол полиглицидиловый эфир</li>
          </div>
          <div class="base_element">
            <li>Пиперазин</li>
          </div>
        </template>
      </the-base-card-style>
      <!-- Card for Production of polyamide fibers -->
      <the-base-card-style>
        <template v-slot:base_item></template>
        <template v-slot:base_icon>
          <!-- SVG Icon for Poliamide Fibers -->
          <svg
            class="svg_image"
            xmlns="http://www.w3.org/2000/svg"
            xml:space="preserve"
            width="18.75mm"
            height="18.75mm"
            version="1.1"
            style="
              shape-rendering: geometricPrecision;
              text-rendering: geometricPrecision;
              image-rendering: optimizeQuality;
              fill-rule: evenodd;
              clip-rule: evenodd;
            "
            viewBox="0 0 383.6 383.6"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xmlns:xodm="http://www.corel.com/coreldraw/odm/2003"
          >
            <g id="Слой_x0020_1">
              <metadata id="CorelCorpID_0Corel-Layer" />
              <g id="_2632800520912">
                <g>
                  <path
                    class="poliamideFiberfil0"
                    d="M191.8 21.65c93.82,0 170.15,76.33 170.15,170.15 0,93.82 -76.33,170.15 -170.15,170.15 -93.82,0 -170.15,-76.33 -170.15,-170.15 -0,-93.82 76.33,-170.15 170.15,-170.15l0 0zm0 -21.65c-105.93,0 -191.8,85.87 -191.8,191.8 0,105.93 85.87,191.8 191.8,191.8 105.93,0 191.8,-85.87 191.8,-191.8 0,-105.93 -85.87,-191.8 -191.8,-191.8l0 0z"
                  />
                </g>
              </g>
              <line
                class="poliamideFiberfil1 poliamideFiberstr0"
                x1="360.56"
                y1="135.71"
                x2="363.58"
                y2="135.71"
              />
              <path
                class="poliamideFiberfil1 poliamideFiberstr0"
                d="M75.82 135.71l31.58 0c21.52,0 35.34,22.41 26.35,41.44 -0.52,0.92 -0.79,1.41 -0.79,1.41l-15.79 28.2c-10.86,19.39 3.16,43.31 25.39,43.31l31.58 0c22.23,0 36.25,-23.92 25.39,-43.31 0,0 -13.95,-24.91 -15.63,-27.91 -10.69,-19.37 3.32,-43.15 25.48,-43.15l31.58 0c22.18,0 36.18,23.8 25.47,43.17 -1.75,3.12 -15.61,27.88 -15.61,27.88 -10.86,19.39 3.16,43.31 25.39,43.31l31.58 0"
              />
              <path
                class="poliamideFiberfil1 poliamideFiberstr0"
                d="M253.81 310.13c-7.22,-8.74 -9.31,-21.54 -3,-32.81 0,0 13.86,-24.76 15.61,-27.88 10.71,-19.37 -3.29,-43.17 -25.47,-43.17l-31.58 0c-22.16,0 -36.17,23.78 -25.48,43.15 1.68,3 15.63,27.91 15.63,27.91 9.15,16.34 0.64,35.88 -15.56,41.64 -10.8,-19.37 3.21,-43.23 25.41,-43.23l31.58 0c15.15,0 26.49,11.12 28.73,24.34"
              />
              <path
                class="poliamideFiberfil1 poliamideFiberstr0"
                d="M75.82 206.26l31.58 0c21.52,0 35.34,22.41 26.35,41.44 -0.52,0.92 -0.79,1.41 -0.79,1.41l-15.79 28.2c-4.07,7.27 -4.64,15.18 -2.6,22.23"
              />
              <path
                class="poliamideFiberfil1 poliamideFiberstr0"
                d="M89.75 275.72l17.64 0c19.92,0 33.25,19.21 27.99,37.15"
              />
              <path
                class="poliamideFiberfil1 poliamideFiberstr0"
                d="M268.51 84.18c2.53,7.34 2.19,15.77 -2.08,23.48 -1.75,3.12 -15.61,27.88 -15.61,27.88 -10.86,19.39 3.16,43.31 25.39,43.31l31.58 0"
              />
              <path
                class="poliamideFiberfil1 poliamideFiberstr0"
                d="M247.88 71.99c-4.29,17.57 8.88,35.88 28.33,35.88l17.64 0"
              />
              <path
                class="poliamideFiberfil1 poliamideFiberstr0"
                d="M114.1 84.93c2.77,12.6 13.84,22.95 28.46,22.95l31.58 0c21.71,0 35.59,-22.82 26.11,-41.95 -16.6,5.44 -25.47,25.18 -16.34,41.72 1.68,3 15.63,27.91 15.63,27.91 10.86,19.39 -3.16,43.31 -25.39,43.31l-31.58 0c-22.23,0 -36.25,-23.92 -25.39,-43.31l15.79 -28.2c0,0 0.27,-0.49 0.79,-1.41 5.09,-10.76 2.87,-22.61 -3.91,-30.87"
              />
            </g>
          </svg>
        </template>
        <template v-slot:base_title_active
          ><router-link
            v-on:click="scrollToTop"
            to="/productsForPolyamideFibers"
          >
            Производство полиамидных волокон
          </router-link></template
        >
        <template v-slot:base_description>
          <div class="base_element">
            <li>Avifil PA RDC</li>
          </div>
          <div class="base_element">
            <li>Novafil ANF</li>
          </div>
        </template>
      </the-base-card-style>
      <!-- Card for Production of fiberglass -->
      <the-base-card-style>
        <template v-slot:base_item></template>
        <template v-slot:base_icon>
          <!-- SVG for Fiberglass -->
          <svg
            class="svg_image"
            xmlns="http://www.w3.org/2000/svg"
            xml:space="preserve"
            width="18.75mm"
            height="18.75mm"
            version="1.1"
            style="
              shape-rendering: geometricPrecision;
              text-rendering: geometricPrecision;
              image-rendering: optimizeQuality;
              fill-rule: evenodd;
              clip-rule: evenodd;
            "
            viewBox="0 0 209.53 209.53"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xmlns:xodm="http://www.corel.com/coreldraw/odm/2003"
          >
            <g id="Слой_x0020_1">
              <metadata id="CorelCorpID_0Corel-Layer" />
              <g id="_2632814138688">
                <g>
                  <path
                    class="fiberglassfil0"
                    d="M104.76 11.83c51.25,0 92.94,41.69 92.94,92.94 0,51.25 -41.69,92.94 -92.94,92.94 -51.25,0 -92.94,-41.69 -92.94,-92.94 0,-51.25 41.69,-92.94 92.94,-92.94l0 0zm0 -11.83c-57.86,0 -104.76,46.9 -104.76,104.76 0,57.86 46.9,104.76 104.76,104.76 57.86,0 104.76,-46.9 104.76,-104.76 0,-57.86 -46.9,-104.76 -104.76,-104.76l0 0z"
                  />
                </g>
              </g>
              <line
                class="fiberglassfil1 fiberglassstr0"
                x1="163.39"
                y1="57.37"
                x2="163.39"
                y2="161.91"
              />
              <line
                class="fiberglassfil1 fiberglassstr0"
                x1="150.37"
                y1="57.37"
                x2="150.37"
                y2="161.91"
              />
              <line
                class="fiberglassfil1 fiberglassstr0"
                x1="139.07"
                y1="57.37"
                x2="139.07"
                y2="161.91"
              />
              <line
                class="fiberglassfil1 fiberglassstr0"
                x1="126.48"
                y1="57.37"
                x2="126.48"
                y2="161.91"
              />
              <line
                class="fiberglassfil1 fiberglassstr0"
                x1="114.03"
                y1="57.37"
                x2="114.03"
                y2="161.91"
              />
              <line
                class="fiberglassfil1 fiberglassstr0"
                x1="111.32"
                y1="51.27"
                x2="111.32"
                y2="128.81"
              />
              <line
                class="fiberglassfil1 fiberglassstr0"
                x1="169.4"
                y1="152.89"
                x2="77.12"
                y2="152.89"
              />
              <line
                class="fiberglassfil1 fiberglassstr0"
                x1="169.4"
                y1="139.88"
                x2="107.45"
                y2="139.88"
              />
              <line
                class="fiberglassfil1 fiberglassstr0"
                x1="169.4"
                y1="128.57"
                x2="114.03"
                y2="128.57"
              />
              <line
                class="fiberglassfil1 fiberglassstr0"
                x1="169.4"
                y1="115.98"
                x2="114.03"
                y2="115.98"
              />
              <line
                class="fiberglassfil1 fiberglassstr0"
                x1="169.4"
                y1="103.54"
                x2="114.03"
                y2="103.54"
              />
              <line
                class="fiberglassfil1 fiberglassstr0"
                x1="169.4"
                y1="90.99"
                x2="114.03"
                y2="90.99"
              />
              <line
                class="fiberglassfil1 fiberglassstr0"
                x1="169.4"
                y1="79.69"
                x2="114.03"
                y2="79.69"
              />
              <line
                class="fiberglassfil1 fiberglassstr0"
                x1="169.4"
                y1="67.1"
                x2="114.03"
                y2="67.1"
              />
              <line
                class="fiberglassfil1 fiberglassstr0"
                x1="101.02"
                y1="145.88"
                x2="101.02"
                y2="161.91"
              />
              <line
                class="fiberglassfil1 fiberglassstr0"
                x1="104.59"
                y1="42.35"
                x2="104.59"
                y2="123.28"
              />
              <line
                class="fiberglassfil1 fiberglassstr0"
                x1="89.71"
                y1="151.13"
                x2="89.71"
                y2="161.91"
              />
              <line
                class="fiberglassfil1 fiberglassstr0"
                x1="96.63"
                y1="38.2"
                x2="96.63"
                y2="117.03"
              />
              <line
                class="fiberglassfil1 fiberglassstr0"
                x1="77.12"
                y1="152.89"
                x2="77.12"
                y2="161.91"
              />
              <line
                class="fiberglassfil1 fiberglassstr0"
                x1="87.09"
                y1="35.63"
                x2="87.09"
                y2="114.16"
              />
              <line
                class="fiberglassfil1 fiberglassstr0"
                x1="67.73"
                y1="114.41"
                x2="67.73"
                y2="35.63"
              />
              <line
                class="fiberglassfil1 fiberglassstr0"
                x1="58.05"
                y1="116.54"
                x2="58.05"
                y2="37.7"
              />
              <line
                class="fiberglassfil1 fiberglassstr0"
                x1="48.51"
                y1="123.28"
                x2="48.51"
                y2="42.35"
              />
              <line
                class="fiberglassfil1 fiberglassstr0"
                x1="65.11"
                y1="151.51"
                x2="65.11"
                y2="158.9"
              />
              <line
                class="fiberglassfil1 fiberglassstr0"
                x1="77.89"
                y1="33.48"
                x2="77.89"
                y2="112.22"
              />
              <line
                class="fiberglassfil1 fiberglassstr0"
                x1="54.66"
                y1="147.39"
                x2="54.66"
                y2="152.89"
              />
              <line
                class="fiberglassfil1 fiberglassstr0"
                x1="47.72"
                y1="142"
                x2="47.72"
                y2="145.88"
              />
              <line
                class="fiberglassfil1 fiberglassstr0"
                x1="42.07"
                y1="50.44"
                x2="42.07"
                y2="136.87"
              />
              <ellipse
                class="fiberglassfil1 fiberglassstr0"
                cx="76.69"
                cy="128.81"
                rx="34.62"
                ry="24.08"
              />
              <path
                class="fiberglassfil1 fiberglassstr0"
                d="M42.07 65.52c0,-13.3 15.5,-24.08 34.62,-24.08 19.12,0 34.62,10.78 34.62,24.08"
              />
              <path
                class="fiberglassfil1 fiberglassstr0"
                d="M42.07 76.06c0,-13.3 15.5,-24.08 34.62,-24.08 19.12,0 34.62,10.78 34.62,24.08"
              />
              <path
                class="fiberglassfil1 fiberglassstr0"
                d="M42.07 86.47c0,-13.3 15.5,-24.08 34.62,-24.08 19.12,0 34.62,10.78 34.62,24.08"
              />
              <path
                class="fiberglassfil1 fiberglassstr0"
                d="M42.07 97c0,-13.3 15.5,-24.08 34.62,-24.08 19.12,0 34.62,10.78 34.62,24.08"
              />
              <path
                class="fiberglassfil1 fiberglassstr0"
                d="M42.07 107.37c0,-13.3 15.5,-24.08 34.62,-24.08 19.12,0 34.62,10.78 34.62,24.08"
              />
              <path
                class="fiberglassfil1 fiberglassstr0"
                d="M42.07 117.91c0,-13.3 15.5,-24.08 34.62,-24.08 19.12,0 34.62,10.78 34.62,24.08"
              />
            </g>
          </svg>
        </template>
        <template v-slot:base_title_active
          ><router-link v-on:click="scrollToTop" to="/productsForFiberglass">
            Производство стекловолокна
          </router-link></template
        >
        <template v-slot:base_description>
          <div class="base_element">
            <li>Achilon ZS</li>
          </div>
          <div class="base_element">
            <li>Polysorbate 81</li>
          </div>
          <div class="base_element">
            <li>Неионная дисперсия ненасыщенной бисфенольной смолы</li>
          </div>
          <div class="base_element">
            <li>FS631H (61-63%)</li>
          </div>
        </template>
      </the-base-card-style>
      <!-- Card for Surfactants for industry -->
      <the-base-card-style>
        <template v-slot:base_item></template>
        <template v-slot:base_icon>
          <!-- SVG for Sulfactants -->
          <svg
            class="svg_image"
            xmlns="http://www.w3.org/2000/svg"
            xml:space="preserve"
            width="18.75mm"
            height="18.75mm"
            version="1.1"
            style="
              shape-rendering: geometricPrecision;
              text-rendering: geometricPrecision;
              image-rendering: optimizeQuality;
              fill-rule: evenodd;
              clip-rule: evenodd;
            "
            viewBox="0 0 90.45 90.45"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xmlns:xodm="http://www.corel.com/coreldraw/odm/2003"
          >
            <g id="Слой_x0020_1">
              <metadata id="CorelCorpID_0Corel-Layer" />
              <g id="_2632367299840">
                <g>
                  <path
                    class="sulfactantfil0"
                    d="M45.22 5.11c22.12,0 40.12,18 40.12,40.12 0,22.12 -18,40.12 -40.12,40.12 -22.12,0 -40.12,-18 -40.12,-40.12 0,-22.12 18,-40.12 40.12,-40.12l0 0zm0 -5.11c-24.98,0 -45.22,20.25 -45.22,45.22 0,24.98 20.25,45.22 45.22,45.22 24.98,0 45.22,-20.25 45.22,-45.22 0,-24.98 -20.25,-45.22 -45.22,-45.22l0 0z"
                  />
                </g>
              </g>
              <circle class="sulfactantfil1 sulfactantstr0" cx="45.22" cy="45.22" r="18.09" />
              <circle class="sulfactantfil1 sulfactantstr0" cx="45.22" cy="45.22" r="27.13" />
              <circle class="sulfactantfil0" cx="72.36" cy="45.22" r="2.55" />
              <circle class="sulfactantfil0" cx="71.26" cy="53.26" r="2.55" />
              <circle class="sulfactantfil0" cx="67.65" cy="60.64" r="2.55" />
              <circle class="sulfactantfil0" cx="61.88" cy="66.71" r="2.55" />
              <circle class="sulfactantfil0" cx="54.29" cy="70.84" r="2.55" />
              <circle class="sulfactantfil0" cx="45.23" cy="72.36" r="2.55" />
              <circle class="sulfactantfil0" cx="18.09" cy="45.22" r="2.55" />
              <circle class="sulfactantfil0" cx="19.19" cy="53.26" r="2.55" />
              <circle class="sulfactantfil0" cx="22.81" cy="60.64" r="2.55" />
              <circle class="sulfactantfil0" cx="28.58" cy="66.71" r="2.55" />
              <circle class="sulfactantfil0" cx="36.17" cy="70.84" r="2.55" />
              <circle class="sulfactantfil0" cx="71.25" cy="37.19" r="2.55" />
              <circle class="sulfactantfil0" cx="67.64" cy="29.81" r="2.55" />
              <circle class="sulfactantfil0" cx="61.87" cy="23.73" r="2.55" />
              <circle class="sulfactantfil0" cx="54.28" cy="19.61" r="2.55" />
              <circle class="sulfactantfil0" cx="45.22" cy="18.09" r="2.55" />
              <circle class="sulfactantfil0" cx="18.09" cy="45.22" r="2.55" />
              <circle class="sulfactantfil0" cx="19.19" cy="37.19" r="2.55" />
              <circle class="sulfactantfil0" cx="22.81" cy="29.81" r="2.55" />
              <circle class="sulfactantfil0" cx="28.58" cy="23.73" r="2.55" />
              <circle class="sulfactantfil0" cx="36.17" cy="19.61" r="2.55" />
              <g id="_2632367284720">
                <path
                  class="sulfactantfil1 sulfactantstr1"
                  d="M44.54 31.65c0,-3.39 1.36,-3.39 1.36,-6.78 0,-3.39 -1.36,-3.39 -1.36,-6.78"
                />
              </g>
              <g id="_2632367283520">
                <path
                  class="sulfactantfil1 sulfactantstr1"
                  d="M44.55 72.33c0,-3.39 1.35,-3.39 1.35,-6.77 0,-3.39 -1.35,-3.39 -1.35,-6.77"
                />
              </g>
              <g id="_2632367284768">
                <path
                  class="sulfactantfil1 sulfactantstr1"
                  d="M72.37 45.9c-3.4,0 -3.4,-1.36 -6.79,-1.36 -3.4,0 -3.4,1.36 -6.79,1.36"
                />
              </g>
              <g id="_2632367288512">
                <path
                  class="sulfactantfil1 sulfactantstr1"
                  d="M31.66 45.9c-3.39,0 -3.39,-1.36 -6.78,-1.36 -3.39,0 -3.39,1.36 -6.78,1.36"
                />
              </g>
              <g id="_2632367288224">
                <path
                  class="sulfactantfil1 sulfactantstr1"
                  d="M53.74 71.05c-1.14,-3.21 0.07,-3.64 -1.08,-6.84 -1.14,-3.21 -2.35,-2.78 -3.49,-5.98"
                />
              </g>
              <g id="_2632367288992">
                <path
                  class="sulfactantfil1 sulfactantstr1"
                  d="M40.08 32.65c-1.14,-3.2 0.07,-3.63 -1.07,-6.83 -1.14,-3.2 -2.35,-2.77 -3.48,-5.97"
                />
              </g>
              <g id="_2632367286832">
                <path
                  class="sulfactantfil1 sulfactantstr1"
                  d="M61.45 67.04c-2.09,-2.69 -1.24,-3.35 -3.32,-6.05 -2.09,-2.69 -2.94,-2.03 -5.03,-4.73"
                />
              </g>
              <g id="_2632371906192">
                <path
                  class="sulfactantfil1 sulfactantstr1"
                  d="M36.49 34.83c-2.08,-2.69 -1.23,-3.35 -3.32,-6.04 -2.08,-2.69 -2.93,-2.03 -5.02,-4.72"
                />
              </g>
              <g id="_2632371911808">
                <path
                  class="sulfactantfil1 sulfactantstr1"
                  d="M67.33 61.1c-2.81,-1.93 -2.17,-2.86 -4.99,-4.79 -2.81,-1.93 -3.45,-1.01 -6.26,-2.94"
                />
              </g>
              <g id="_2632371912096">
                <path
                  class="sulfactantfil1 sulfactantstr1"
                  d="M33.72 38c-2.81,-1.93 -2.17,-2.86 -4.98,-4.79 -2.81,-1.93 -3.44,-1 -6.25,-2.94"
                />
              </g>
              <g id="_2632371918096">
                <path
                  class="sulfactantfil1 sulfactantstr1"
                  d="M71.07 53.89c-3.27,-1.01 -2.88,-2.26 -6.15,-3.27 -3.27,-1.01 -3.65,0.24 -6.92,-0.77"
                />
              </g>
              <g id="_2632371922080">
                <path
                  class="sulfactantfil1 sulfactantstr1"
                  d="M32.06 41.85c-3.26,-1.01 -2.88,-2.26 -6.14,-3.26 -3.26,-1.01 -3.65,0.24 -6.91,-0.77"
                />
              </g>
              <g id="_2632371922608">
                <path
                  class="sulfactantfil1 sulfactantstr1"
                  d="M71.66 37.83c-3.32,1.02 -3.7,-0.25 -7.02,0.77 -3.32,1.02 -2.93,2.29 -6.24,3.3"
                />
              </g>
              <g id="_2632371861600">
                <path
                  class="sulfactantfil1 sulfactantstr1"
                  d="M32.46 49.87c-3.27,1.01 -3.65,-0.24 -6.92,0.76 -3.27,1.01 -2.88,2.25 -6.15,3.26"
                />
              </g>
              <g id="_2632371860496">
                <path
                  class="sulfactantfil1 sulfactantstr1"
                  d="M67.97 30.28c-2.81,1.93 -3.45,1 -6.26,2.93 -2.81,1.93 -2.17,2.86 -4.99,4.79"
                />
              </g>
              <g id="_2632371862416">
                <path
                  class="sulfactantfil1 sulfactantstr1"
                  d="M34.36 53.38c-2.81,1.93 -3.44,1 -6.25,2.94 -2.81,1.93 -2.17,2.86 -4.98,4.79"
                />
              </g>
              <g id="_2632371864000">
                <path
                  class="sulfactantfil1 sulfactantstr1"
                  d="M62.29 24.07c-2.08,2.69 -2.93,2.03 -5.02,4.72 -2.08,2.69 -1.23,3.35 -3.32,6.04"
                />
              </g>
              <g id="_2632371865584">
                <path
                  class="sulfactantfil1 sulfactantstr1"
                  d="M37.34 56.27c-2.08,2.69 -2.94,2.03 -5.02,4.73 -2.08,2.69 -1.23,3.35 -3.31,6.05"
                />
              </g>
              <g id="_2632371869328">
                <path
                  class="sulfactantfil1 sulfactantstr1"
                  d="M54.89 19.82c-1.13,3.21 -2.34,2.78 -3.48,5.99 -1.13,3.21 0.08,3.63 -1.06,6.84"
                />
              </g>
              <g id="_2632371870576">
                <path
                  class="sulfactantfil1 sulfactantstr1"
                  d="M41.3 58.22c-1.13,3.21 -2.34,2.78 -3.48,5.99 -1.13,3.21 0.08,3.63 -1.06,6.84"
                />
              </g>
            </g>
          </svg>
        </template>
        <template v-slot:base_title_active
          ><router-link v-on:click="scrollToTop" to="/surfactantsForIndustry">
            Антислеживатель для производства удобрений
          </router-link></template
        >
        <template v-slot:base_description>
          <div class="base_element">
            <li>Uresoft 150</li>
          </div>
        </template>
      </the-base-card-style>
    </template>
  </the-base-style>
</template>

<script>
export default {
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>


<style scoped>
.background_industry {
  background-image: linear-gradient(
      to top,
      rgba(0, 132, 89, 1),
      rgba(0, 132, 89, 0.8)
    ),
    url(../../assets/textile_industry.jpg);
  background-size: cover;
  position: relative;
  height: 80vh;
  background-position-y: 40%;
  clip-path: polygon(0 0, 100% 0%, 100% 91%, 0 100%, 0 100%);
}
/* SVG Icon Style */
.svg_image {
  width: 100px;
  height: auto;
  border: 3px solid transparent;
  border-radius: 50%;
  background-color: white;
}
/* Style for Polyester Fiber SVG  */
.polyesterFiberstr0 {
  stroke: #008259;
  stroke-width: 136.52;
  stroke-linecap: round;
  stroke-miterlimit: 22.9256;
}
.polyesterFiberfil1 {
  fill: none;
  fill-rule: nonzero;
}
.polyesterFiberfil0 {
  fill: #008259;
  fill-rule: nonzero;
}
/* Style for Poliamide Fibers SVG */
.poliamideFiberstr0 {
  stroke: #008259;
  stroke-width: 5.41;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 22.9256;
}
.poliamideFiberfil1 {
  fill: none;
  fill-rule: nonzero;
}
.poliamideFiberfil0 {
  fill: #008259;
  fill-rule: nonzero;
}
/* Style for Fiberglass SVG */
.fiberglassstr0 {
  stroke: #008259;
  stroke-width: 2.96;
  stroke-linecap: round;
  stroke-miterlimit: 22.9256;
}
.fiberglassfil1 {
  fill: none;
  fill-rule: nonzero;
}
.fiberglassfil0 {
  fill: #008259;
  fill-rule: nonzero;
}
/* Style for Sulfactant's SVG */
.sulfactantstr0 {
  stroke: #008259;
  stroke-width: 0.64;
  stroke-linecap: round;
  stroke-miterlimit: 22.9256;
}
.sulfactantstr1 {
  stroke: #008259;
  stroke-width: 1.28;
  stroke-linecap: round;
  stroke-miterlimit: 22.9256;
}
.sulfactantfil1 {
  fill: none;
  fill-rule: nonzero;
}
.sulfactantfil0 {
  fill: #008259;
  fill-rule: nonzero;
}

/* Style for elements */
.base_element_active {
  list-style: none;
  text-decoration: none;
  color: rgba(0, 132, 89, 1);
  transition: all 0.2s ease-out;
}
.base_element_active:hover,
.base_element_active:active {
  color: #99cebd;
  cursor: pointer;
}
.base_element_active li {
  margin-bottom: 10px;
}
.base_element {
  font-size: 16px;
  list-style: none;
  text-decoration: none;
  color: rgba(0, 132, 89, 1);
  transition: all 0.2s ease-out;
}
.base_element li {
  margin-bottom: 3px;
}
a {
  text-decoration: none;
  color: #008459;
  transition: all 0.2s ease-out;
}
a:hover,
a:active {
  color: #99cebd;
  cursor: pointer;
}
</style>