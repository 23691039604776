<template>
  <the-base-product-list-style>
    <template v-slot: product></template>
    <template v-slot:product_header>
      <h3></h3>
    </template>
    <template v-slot:product_title>
      <h5>Антислеживатель для производства удобрений</h5>
    </template>
    <template v-slot:product_list>
      <!-- Uresoft 150 -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Uresoft 150</h6>
          
        </template>
        <template v-slot:product_text>
          <p>Смесь анионных поверхностно-активных веществ и полимеров в водном растворе.</p>
          
        </template>
      </the-base-product-item-style>
    </template>
  </the-base-product-list-style>
</template>