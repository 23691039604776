<template>
  <div class="background_not_found">
    <div class="text_block">
    <p class="text_not_found">Неправильно набрали адрес,</p>
    <p class="text_not_found">
      вернитесь на
      <router-link @click="scrollToTop" to="/"><li>главную</li></router-link>
    </p>
  </div>
  </div>
</template>

<script>
export default {
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      this.crossBurgerMenu = false;
      this.linesBurgerMenu = true;
      this.navItemFlex = false;
      this.navOpacity = 0;
      this.stickyBar = false;
    },
  },
};
</script>


<style scoped>
.background_not_found {
  background-image: linear-gradient(
      to top,
      rgba(0, 132, 89, 1),
      rgba(0, 132, 89, 0.8)
    ),
    url(../assets/background.png);
  background-size: cover;
  position: relative;
  height: 100vh;
  width: 100vw;
  justify-items: center;
}
.text_block {
display: flex;
flex-direction: column;
justify-content: center;
width: 100vw;
height: 100vh;



}
.text_not_found {
  color: white;
  font-size: 3.6rem;
  text-align: center;
  }
li {
  list-style: none;
  color: white;
  font-weight: 700;
  transition: all 0.5s linear;
}
li:hover {
  opacity: 0.7;
}
/* Media Queries  for IntroSection */
/* Media Queries 998px */
@media (max-width: 998px) {
.text_not_found {
    font-size: 2.2rem;
  
  }


}

</style>