<template>
  <div class="section">
    <div class="background">
      <slot name="background"></slot>
      
    </div>

    <div class="base_header">
      <slot name="base_header"></slot>
    </div>

    <div class="base_list">
      <slot name="base_list"></slot>
    </div>
  </div>
</template>

<style scoped>
.base_header {
  margin-top: 100px;
  font-size: 3.6rem;
  text-align: center;
  color: white;
  margin-bottom: 50px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
}
.base_list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  gap: 55px;
  padding-bottom: 120px;
  width: 75vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

/* Media Queries */
@media (max-width: 1350px) {
  .base_header {
    font-size: 3.2rem;
  }
}
/* Media Queries (max-width: 1250px) */
@media (max-width: 1250px) {
  .base_header {
    font-size: 3rem;
  }
}
/* Media Queries (max-width: 1200px) */
@media (max-width: 1200px) {
  .base_header {
    font-size: 2.8rem;
  }
}
/* Media Queries (max-width: 998px) */
@media (max-width: 998px) {
  .base_list {
    grid-template-columns: 1fr;
    gap: 15px;
    margin-left: auto;
    margin-right: auto;
    width: 90vw;
  }
  .base_header {
    font-size: 2.4rem;
  }
}

/* Media Queries (max-width: 747px) */
@media (max-width: 747px) {
  .base_header {
    font-size: 2rem;
  }
  .base_list {
    gap: 10px;
  }
}
/* Media Queries (max-width: 500px) */
@media (max-width: 500px) {
  .base_header {
    font-size: 1.8rem;
  }
}
</style>