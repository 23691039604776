<template>
  <the-base-product-list-style>
    <template v-slot: product></template>
    <template v-slot:product_header>
      <h3></h3>
    </template>
    <template v-slot:product_title>
      <h5>Производство стекловолокна</h5>
    </template>
    <template v-slot:product_list>
      
      <the-base-product-item-style v-for="FiberglassObject in FiberglassObjects" v-bind:key="FiberglassObject.id">
        <template v-slot:product_subtitle>
          <h6>{{FiberglassObject.name}}</h6>
        </template>
        <template v-slot:product_text>
          <p>
            {{FiberglassObject.description}}
          </p>
        </template>
      </the-base-product-item-style>
    </template>
  </the-base-product-list-style>
</template>





<script>
export default {
  data() {
    return {
      FiberglassObjects: [
        {
          id: 1,
          name: "Achilon ZS",
          chemicalName: "",
          description:"Катионная парафиносодержащая дисперсия с металическим мылом.",
          url: "",
        },{
          id: 2,
          name: "Polysorbate 81",
          chemicalName: "",
          description:"Полиоксиэтилен (5) Сорбитан Моноолеат.",
          url: "",
        },
        {
          id: 3,
          name: "Неонная дисперсия ненасыщенной бисфенольной смолы",
          chemicalName: "",
          description:"Пленкообразователь для производства замасливателей для обработки стекловолокна.",
          url: "",
        },
        {
          id: 4,
          name: "FS631H (61-63%)",
          chemicalName: "",
          description:"Анионная полиуретановая дисперсия для производства замасливателей для обработки стекловолокна.",
          url: "",
        },
        
        
      ],
    };
  },
};
</script>