<template>
  <the-base-product-list-style>
    <template v-slot: product></template>
    <template v-slot:product_header>
      <h3>Активные ингредиенты</h3>
          </template>
    <template v-slot:product_title>
      <h5>Ботанические экстракты</h5>
      <p>описание</p>
    </template>
    <template v-slot:product_list>
      <!-- ABS Coconut Water PF Description -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>ABS Coconut Water PF</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Cocos Nucifera (Coconut) Water & Leuconostoc/Radish Root
            Ferment Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- ABS Cactus Water SF Description -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>ABS Cactus Water SF</h6>
        </template>
        <template v-slot:product_text>
          <p>INCI: Opuntia Ficus-Indica Stem Water & Lactobacillus Ferment</p>
        </template>
      </the-base-product-item-style>
      <!-- BiEau® Actif Tri-Mushroom Description -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>BiEau® Actif Tri-Mushroom</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Ganoderma Lucidum Extract & Inonotus Obliquus (Mushroom)
            Extract & Cordyceps Sinensis Extract & Lactobaillus Ferment
          </p>
        </template>
      </the-base-product-item-style>
      <!-- BiEau® Actif Brown Algae Description -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>BiEau® Actif Brown Algae</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Laminaria Japonica Extract & Leuconostoc/Radish Root Ferment
            Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- BiEau® Actif Green Algae Description -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>BiEau® Actif Green Algae</h6>
        </template>
        <template v-slot:product_text>
          <p>INCI: Spirulina Platensis Extract & Lactobacillus Ferment</p>
        </template>
      </the-base-product-item-style>
      <!-- BiEau® Actif Birch Description -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>BiEau® Actif Birch</h6>
        </template>
        <template v-slot:product_text>
          <p>INCI: Betula Alba Juice & Lactobacillus Ferment</p>
        </template>
      </the-base-product-item-style>
      <!-- BiEau® Actif Red Algae Description -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>BiEau® Actif Red Algae</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Haematococcus Pluvialis Extract & Yeast Ferment Extract &
            Lactobacillus Ferment
          </p>
        </template>
      </the-base-product-item-style>
      <!-- AC Chicory & Algae Powder Description -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>AC Chicory & Algae Powder</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Algae (Algae Extract) & Cichorium Intybus (Chicory) Root
            Extract & Magnesium Gluconate & Maltose
          </p>
        </template>
      </the-base-product-item-style>
      <!-- AC Alg-MoistEAU AB Description -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>AC Alg-MoistEAU AB</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Aqua & Chondrus Crispus Extract & Populus Tremuloides Bark
            Extract
          </p>
        </template>
      </the-base-product-item-style>
      <!-- ABS White Willow Bark Extract Powder Description -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>ABS White Willow Bark Extract Powder</h6>
        </template>
        <template v-slot:product_text>
          <p>INCI: Salix Alba (Willow) Bark Extract</p>
        </template>
      </the-base-product-item-style>
      <!-- ACB Fruit Mix Description -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>ACB Fruit Mix</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Aqua & Vaccinium Myrtillus Fruit Extract & Saccharum
            Officinarum (Sugar Cane) Extract & Citrus Aurantium Dulcis (Orange)
            Fruit Extract & Citrus Limon (Lemon) Fruit Extract & Acer
            Saccharinum (Sugar Maple) Extract
          </p>
        </template>
      </the-base-product-item-style>
      <!-- ABS Matcha Tea Extract PF Description -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>ABS Matcha Tea Extract PF</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Aqua & Camellia Sinensis (Matcha Tea) Leaf Extract &
            Leuconostoc/Radish Root Ferment Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- ACB Oat extract Beta PF Description -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>ACB Oat extract Beta PF</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Aqua & Lactobacillus/Oat Ferment Extract Filtrate &
            Leuconostoc/Radish Root Ferment Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- ABS Moringa Pterygosperma Seed Extract  Description -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>ABS Moringa Pterygosperma Seed Extract G</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Glycerin & Moringa Oleifera Seed Extract & Leuconostoc/Radish
            Root Ferment Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- ABS Viola Tricolor Extract PF Description -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>ABS Viola Tricolor Extract PF</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Aqua & Hydrolyzed Viola Tricolor Extract & Leuconostoc/Radish
            Root Ferment Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- AC Party Face Pom Description -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>AC Party Face Pom</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Aqua & Punica Granatum Extract & Citrus Aurantium Dulcis
            (Orange) Flower Extract & Leuconostoc/Radish Root Ferment Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- ACB Celluplex PF Description -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>ACB Celluplex PF</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Lactobacillus/Camellia Sinensis Leaf/Clover Flower/Cocoa
            Flower Ferment Filtrate & Aqua & Leuconostoc/Radish Root Ferment
            Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- ABS Oat Milk Extract PG PET Description -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>ABS Oat Milk Extract PG PET</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Aqua & Propylene Glycol & Avena Sativa Kernel Extract &
            Glyceryl Stearate & PEG-60 Hydrogenated Castor Oil & Helianthus
            Annuus Seed Oil & Phenoxyethanol
          </p>
        </template>
      </the-base-product-item-style>
      <!-- ABS Wheat Milk Extract PG PET Description -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>ABS Wheat Milk Extract PG PET</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Aqua & Propylene Glycol & Triticum Vulgare Germ Oil & Triticum
            Vulgare Germ Extract & Glyceryl Stearate & PEG-60 Hydrogenated
            Castor Oil & Phenoxyethanol
          </p>
        </template>
      </the-base-product-item-style>
      <!-- ABS Almond Milk Extract PG PET Description -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>ABS Almond Milk Extract PG PET</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Aqua & Prunus Amygdalus Dulcis Fruit Extract & Prunus
            Amygdalus Dulcis Oil & Glyceryl Stearate & PEG-60 Hydrogenated
            Castor Oil & Propylene Glycol & Phenoxyethanol
          </p>
        </template>
      </the-base-product-item-style>
    </template>
  </the-base-product-list-style>
</template>
