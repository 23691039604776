<template>
  <the-base-product-list-style>
    <template v-slot: product></template>
    <template v-slot:product_header>
      <h3>Активные ингредиенты</h3>
    </template>
    <template v-slot:product_title>
      <h5>Системы доставки активов</h5>
    </template>
    <template v-slot:product_list>
      <!-- AC Hemp Oil Liposome PF -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>AC Hemp Oil Liposome PF</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Aqua & Phospholipids & Leuconostoc/Radish Root Ferment
            Filtrate & Cannabis Sativa Flower/Leaf/ Stem Extract
          </p>
        </template>
      </the-base-product-item-style>
      <!-- AC MVS Lips -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>AC MVS Lips</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Glycine Soja (Soybean) Seed Extract & Glycyrrhiza Glabra
            (Licorice) Leaf Extract & Phospholipids
          </p>
        </template>
      </the-base-product-item-style>
      <!-- AC HYA Liposome PF -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>AC HYA Liposome PF</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Aqua & Phospholipids & Hyaluronic Acid & Leuconostoc/Radish
            Root Ferment Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- AC Cinnamon Liposome -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>AC Cinnamon Liposome</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Aqua & Cinnamomum Cassia Bark Extract & Phospholipids &
            Lactobacillus Ferment
          </p>
        </template>
      </the-base-product-item-style>
      <!-- AC Turmeric Liposome PF -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>AC Turmeric Liposome PF</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Aqua & Curcuma Longa (Turmeric) Root Extract & Phospholipids &
            Leuconostoc/Radish Root Ferment Filtrate
          </p>
        </template>
      </the-base-product-item-style>
    </template>
  </the-base-product-list-style>
</template>

