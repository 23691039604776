<template>
<div>
<div>{{rate}}</div>
  <div class="table">
    <div class="table_item">Дата</div>
    <div class="table_item">Наименование</div>
    <div class="table_item">Код товара</div>
    <div class="table_item">Вес нетто</div>
    <div class="table_item">Вес брутто</div>
    <div class="table_item">Грузовые места</div>
    <div class="table_item">Размер грузового места</div>
    <div class="table_item">Ставка транспортных расходов</div>
    <div class="table_item">Валюта</div>
    <div class="table_item">Компания</div>
    <div class="table_item">MSDS прикреплена</div>
    <div class="table_item"><button class="btn">Корректировать</button></div>

    <div>{{ dateRate }}</div>
    <div>{{ nameGoods }}</div>
    <div>{{ productCode }}</div>
    <div>{{ weightNetto }}</div>
    <div>{{ weightBrutto }}</div>
    <div>{{ cargoPlaces }}</div>
    <div>{{ sizeCargo }}</div>
    <div>{{ currencyRate }}</div>
    <div>{{ currencyType }}</div>
    <div>{{ transportCompany }}</div>
    <div>{{ addFile }}</div>

    <div><button class="btn" v-on:click="deleteTransInfo" >Удалить</button></div>
  </div>
  </div>
</template>


<script>
export default {
  props: [
    "id",
    "rate",
    "dateRate",
    "nameGoods",
    "productCode",
    "deliveryConditions",
    "weightNetto",
    "weightBrutto",
    "cargoPlaces",
    "sizeCargo",
    "currencyRate",
    "currencyType",
    "transportCompany",
    "addFile",
  ],
  emits: ['deleteTransInfo'],

  data() {
    return {
      transInfoObject: [{
        rate: this.rate,
        id: this.id,
        dataRate: this.dateRate,
        nameGoods: this.nameGoods,
        productCode: this.productCode,
        deliveryConditions: this.deliveryConditions,
        weightNetto: this.weightNetto,
        weighBrutto: this.weightBrutto,
        cargoPlaces: this.cargoPlaces,
        sizeCargo: this.sizeCargo,
        currencyRate: this.currencyRate,
        currencyType:this.currencyType,
        transportCompany: this.transportCompany,
        addFile: this.addFile,

      },]
    };
  },
  methods: {
    deleteTransInfo(){
      this.$emit("delete-transport", this.id);
console.log(this.transInfoObject,)


    }
    },
  
};
</script>

<style scoped>
.table {
  display: grid;
  grid-template-columns: 80px 100px 90px 70px 75px 1fr 120px 180px 50px 70px 1fr 120px;
  grid-template-rows: 1fr 1fr;
  gap: 4px;
  background-color: #e6f3ee;
  align-items: center;
  justify-items: center;
  margin-bottom: 10px;
}

.btn {
  background-color: rgba(0, 132, 89, 1);
  color: white;
  font-size: 12px;
  padding: 5px;
  border: none;
  border-radius: 5px;
  transition: all 0.3s linear;
}

.btn:hover,
.btn:active {
  background-color: #339d7a;
  cursor: pointer;
}
</style>