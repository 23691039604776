<template>
  <div class="base_item">
    <slot name="base_item"></slot>
    <div class="base_icon">
      <slot name="base_icon"></slot>
    </div>
    <h3 class="base_title_active">
      <slot name="base_title_active"></slot>
    </h3>
    <h3 class="base_title">
      <slot name="base_title"></slot>
    </h3>
    <div class="base_description">
      <slot name="base_description"></slot>
    </div>
  </div>
</template>


<style scoped>
.base_item {
  display: flex;
  height: 415px;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 0 15px;
  box-shadow: 0px 5px 14px 1px rgba(0, 132, 89, 0.2);
  
}
.base_icon {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100px;
  height: auto;
  padding-top: 55px;
  margin-bottom: 20px;
  transition: all 0.3s ease-out;
}
.base_title,
.base_title_active {
  color: rgba(0, 132, 89, 1);
  text-transform: uppercase;
  text-align: center;
  font-size: 2.4rem;
  font-weight: bold;
  letter-spacing: 2.5px;
  margin-bottom: 18px;
  padding: 0 15px;
  /* transition: all 0.2s ease-out; */
}
/* .base_title_active {
  color: rgba(0, 132, 89, 1);
  text-transform: uppercase;
  text-align: center;
  font-size: 2.4rem;
  font-weight: bold;
  letter-spacing: 2.5px;
  margin-bottom: 18px;
  padding: 0 15px;
  transition: all 0.2s ease-out;
} */
.base_title_active a {
  text-decoration: none;
}
.base_description {
  display: none;
  font-size: 1.8rem;
  color: #666666;
  text-align: center;
  margin-bottom: 10px;
  transition: all 0.2s ease-out;
}
.base_item:hover .base_description {
  display: block;
}
.base_item:hover .base_icon {
  margin-top: -50px;
  padding-top: 0px;
  margin-bottom: 15px;
}
/* .base_item:hover .base_title,
.base_item:hover .base_title_active {
  font-size: 1.8rem;
} */

/* MEDIA QUERIES */
/* Media Queries (max-width: 1350px) */
@media (max-width: 1350px) {
}
/* Media Queries (max-width: 1250px) */
@media (max-width: 1250px) {
}
/* Media Queries (max-width: 1200px) */
@media (max-width: 1200px) {
}
/* Media Queries (max-width: 998px) */
@media (max-width: 998px) {
  .base_item {
    width: 90vw;
  }
}

/* Media Queries (max-width: 747px) */
@media (max-width: 747px) {
}
/* Media Queries (max-width: 500px) */
@media (max-width: 500px) {
}
</style>