<template>
  <the-base-product-list-style>
    <template v-slot: product></template>
    <template v-slot:product_header>
      <h3>Латекс</h3>
    </template>
    <template v-slot:product_title>
      <h5></h5>
    </template>
    <template v-slot:product_list>
      <!-- Laitex B754 -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Laitex B754</h6>
        </template>
        <template v-slot:product_text>
          <p>
            Бутадиен-стирольный латекс с высоким содержанием твердых веществ 
            для приготовления катионной асфальтовой эмульсии.
          </p>
          <p>Преимущества:</p>
          <li>Снижение чувствительности модифицированного связующего к температуре.</li>
          <li>При низких температурах Laitex B754 демонстрирует превосходную гибкость.</li>
          <li>При высоких температурах асфальтовое вяжущее демонстрирует хорошую вязкоупругость.</li>
        </template>
      </the-base-product-item-style>
      <!-- Laitex B733 -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Laitex B733</h6>
        </template>
        <template v-slot:product_text>
          <p>
            Бутадиен-стирольный латекс с высоким содержанием твердых веществ 
            для приготовления катионной асфальтовой эмульсии.
          </p>
          <p>Преимущества:</p>
          <li>Повышенная эластичность и улучшенная прочность.</li>
          <li>Гибкость при низких температурах.</li>
          <li>Устойчивость к остаточной деформации при высоких температурах.</li>
          <li>Быстрое сцепление между слоями.</li>
          <li>Повышенная водостойкость.</li>
        </template>
      </the-base-product-item-style>
      <!-- Laitex B475 -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Laitex B475</h6>
        </template>
        <template v-slot:product_text>
          <p>
            Анионный латекс, который улучшает асфальтовые эмульсии, гидроизоляцию вертикальных стен,
            фундаментов, битумных листов.
          </p>
          <p>Преимущества:</p>
          <li>Повышенная водостойкость</li>
          <li>Повышает стойкость к истиранию</li>
          <li>Превращает асфальт в противоскользящее покрытие.</li>
          <li>Повышает температуру размягчения.</li>
        </template>
      </the-base-product-item-style>
    </template>
  </the-base-product-list-style>
</template>