<template>
  <div>
    <div class="background_intro polygon">
      <!-- <heptagon-layout></heptagon-layout> -->
      <div class="intro">
        <div
          class="intro_item intro_logo"
          v-bind:class="{ hideItem: hideLogo }"
        >
          <div class="intro_img">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xml:space="preserve"
              width="50.8791mm"
              height="33.0075mm"
              version="1.1"
              style="
                shape-rendering: geometricPrecision;
                text-rendering: geometricPrecision;
                image-rendering: optimizeQuality;
                fill-rule: evenodd;
                clip-rule: evenodd;
              "
              viewBox="0 0 4777.49 3099.37"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xmlns:xodm="http://www.corel.com/coreldraw/odm/2003"
            >
              <g id="Слой_x0020_1">
                <metadata id="CorelCorpID_0Corel-Layer" />
                <g id="_2134793989408">
                  <path
                    class="fil0"
                    d="M3086.21 1353.42c-0.01,-0.09 -855.86,0 -855.86,0 -483.6,0 -877.02,393.42 -877.02,877.01l0 270.06 392.26 -270.06c0,-267.27 217.48,-484.75 484.75,-484.75l459.23 0c-24.39,142.13 -74.18,275.63 -145.54,395.19 70.26,117.59 160.55,221.81 266.48,307.66 181.34,-253.7 288.59,-563.78 288.59,-898.7 0,-66.41 -4.75,-132.18 -12.9,-196.41z"
                  />
                  <path
                    class="fil0"
                    d="M2277.14 2448.58c-198.98,161.39 -452.03,258.53 -727.59,258.53 -638.14,0 -1157.29,-519.15 -1157.29,-1157.28 0,-638.14 519.16,-1157.29 1157.29,-1157.29 161.37,0 314.88,33.68 454.52,93.65l348.44 -260.4c-234.34,-142.75 -509.04,-225.5 -802.96,-225.5 -854.43,0 -1549.55,695.11 -1549.55,1549.55 0,854.42 695.11,1549.54 1549.55,1549.54 378.27,0 725.09,-136.49 994.42,-362.48 -100.6,-84.32 -190.3,-181.37 -266.82,-288.31z"
                  />
                </g>
                <g id="_2134793994304">
                  <path
                    class="fil1"
                    d="M2230.35 1353.42l167.82 0c93.48,-545.01 569.15,-961.17 1140.41,-961.17 375.61,0 709.86,179.99 921.41,458.12l313.71 -235.21c-283.18,-373.43 -731.38,-615.17 -1235.12,-615.17 -799.12,0 -1458.62,607.01 -1540.73,1384.7 74.22,-20.47 151.87,-31.28 232.5,-31.28z"
                  />
                  <path
                    class="fil1"
                    d="M4463.79 2243.89c-211.35,280.92 -547.39,462.94 -925.21,462.94 -571.26,0 -1046.87,-416.15 -1140.35,-961.16l-167.88 0c-79.6,0 -154.57,19.16 -220.9,53.31 119.88,735.98 759.76,1300.1 1529.12,1300.1 505.94,0 955.94,-243.77 1238.91,-619.97l-313.7 -235.23z"
                  />
                </g>
              </g>
            </svg>
          </div>
          <div class="logo_text">
            <svg
              class="svg_text"
              xmlns="http://www.w3.org/2000/svg"
              xml:space="preserve"
              width="57.1882mm"
              height="15.9646mm"
              version="1.1"
              style="
                shape-rendering: geometricPrecision;
                text-rendering: geometricPrecision;
                image-rendering: optimizeQuality;
                fill-rule: evenodd;
                clip-rule: evenodd;
              "
              viewBox="0 0 5718.82 1596.46"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xmlns:xodm="http://www.corel.com/coreldraw/odm/2003"
            >
              <g id="Слой_x0020_1">
                <metadata id="CorelCorpID_0Corel-Layer" />
                <g id="_2134793952880">
                  <path
                    class="fil0"
                    d="M528.14 170.64c-46.08,-47.79 -113.48,-76.79 -186,-76.79 -141.63,0 -250.84,101.52 -250.84,244.87 0,137.36 118.6,238.06 251.68,238.06 63.99,0 122.87,-23.05 167.24,-61.46l0 -116.03 -81.9 0 -40.11 -92.12 214.15 0 0 247.42c-63.99,71.66 -157.84,115.17 -260.22,115.17 -192.83,0 -342.14,-150.17 -342.14,-342.13 0,-187.69 160.41,-327.63 342.98,-327.63 95.57,0 182.59,38.38 244.88,100.67l-59.72 69.97z"
                  />
                  <path
                    class="fil0"
                    d="M1118.47 521.31c21.33,35.83 52.04,39.24 74.23,39.24l30.71 0 0 94.71 -38.4 0c-46.07,0 -87.88,-3.41 -128.83,-69.97l-68.26 -110.9 -5.96 0c-69.11,0 -87.89,6.82 -123.71,25.6l0 155.27 -89.59 0 0 -441.97 284.96 0c81.06,0 139.08,56.32 139.08,133.12 0,67.38 -37.56,109.2 -101.53,128.81l27.3 46.09zm-64.85 -134.8l0 0c26.46,0 49.49,-14.51 49.49,-42.65 0,-39.28 -34.13,-42.69 -64.84,-42.69l-180.02 0 0 110.93c34.12,-18.77 77.63,-25.59 123.71,-25.59l71.66 0z"
                  />
                  <path
                    class="fil0"
                    d="M1480.98 566.51l277.3 0 0 88.75 -364.32 0 0 -441.97 348.09 0 0 87.88 -261.07 0 0 107.52c38.4,-22.18 87.02,-25.59 138.22,-25.59l64.84 0 0 87.88 -64.84 0c-81.05,0 -99.82,5.1 -138.22,25.6l0 69.93z"
                  />
                  <path
                    class="fil0"
                    d="M2011.61 566.51l277.3 0 0 88.75 -364.32 0 0 -441.97 348.11 0 0 87.88 -261.09 0 0 107.52c38.4,-22.18 87.03,-25.59 138.22,-25.59l64.84 0 0 87.88 -64.84 0c-81.05,0 -99.82,5.1 -138.22,25.6l0 69.93z"
                  />
                  <polygon
                    class="fil0"
                    points="2518.35,213.29 2785.4,520.45 2785.4,213.29 2873.28,213.29 2873.28,655.26 2785.4,655.26 2539.68,367.73 2539.68,655.26 2451.79,655.26 2451.79,278.98 2408.28,213.29 "
                  />
                  <path
                    class="fil0"
                    d="M3554.88 170.64c-46.06,-47.79 -113.48,-76.79 -186,-76.79 -141.62,0 -250.83,101.52 -250.83,244.87 0,137.36 118.61,238.06 251.7,238.06 82.75,0 156.13,-38.41 202.19,-99.84l57.19 77.65c-64.01,71.66 -157.86,115.17 -260.25,115.17 -192.82,0 -342.13,-150.17 -342.13,-342.13 0,-187.69 160.4,-327.63 343,-327.63 95.56,0 182.59,38.38 244.87,100.67l-59.74 69.97z"
                  />
                  <path
                    class="fil0"
                    d="M3861.12 655.26l-87.02 0 0 -441.97 87.02 0 0 176.63c16.19,-7.69 34.11,-11.1 52.02,-11.1 36.69,0 88.75,12.78 133.12,12.78 18.77,0 37.55,-2.54 55.46,-11.09l0 -167.22 87.03 0 0 441.97 -87.03 0 0 -185.14c-17.08,8.54 -34.14,11.09 -52.05,11.09 -46.06,0 -89.57,-11.95 -133.12,-11.95 -19.6,0 -37.52,2.55 -55.43,10.23l0 175.77z"
                  />
                  <path
                    class="fil0"
                    d="M4480.48 566.51l277.29 0 0 88.75 -364.31 0 0 -441.97 348.09 0 0 87.88 -261.07 0 0 107.52c38.38,-22.18 87.02,-25.59 138.22,-25.59l64.83 0 0 87.88 -64.83 0c-81.07,0 -99.84,5.1 -138.22,25.6l0 69.93z"
                  />
                  <polygon
                    class="fil0"
                    points="5434.25,284.12 5434.25,655.26 5337.86,655.26 5337.86,396.74 5181.73,604.93 5025.56,396.74 5025.56,655.26 4929.17,655.26 4929.17,284.12 4885.66,213.29 4993.14,213.29 5181.73,463.3 5370.28,213.29 5477.76,213.29 "
                  />
                  <polygon
                    class="fil0"
                    points="5627.01,655.26 5627.01,213.29 5714.03,213.29 5714.03,655.26 "
                  />
                </g>
                <g id="_2134793967616">
                  <path
                    class="fil1"
                    d="M108.42 1004.73c0.71,21.43 -14.99,38.55 -39.98,38.55 -22.12,0 -37.83,-17.12 -37.83,-38.55 0,-22.12 16.42,-39.24 39.26,-39.24 23.56,0 38.55,17.12 38.55,39.24zm-69.95 442.56l0 0 0 -345.48 62.8 0 0 345.48 -62.8 0z"
                  />
                  <path
                    class="fil1"
                    d="M205.52 1195.31c0,-35.69 -0.71,-64.94 -2.86,-93.5l55.67 0 3.57 57.12 1.43 0c17.14,-32.83 57.1,-64.97 114.2,-64.97 47.84,0 122.06,28.56 122.06,147.03l0 206.3 -62.8 0 0 -199.17c0,-55.67 -20.7,-102.04 -79.94,-102.04 -41.41,0 -73.52,29.25 -84.24,64.22 -2.86,7.85 -4.28,18.57 -4.28,29.28l0 207.71 -62.81 0 0 -251.98z"
                  />
                  <path
                    class="fil1"
                    d="M601.72 1195.31c0,-35.69 -0.72,-64.94 -2.86,-93.5l55.68 0 3.58 57.12 1.41 0c17.14,-32.83 57.11,-64.97 114.21,-64.97 47.83,0 122.06,28.56 122.06,147.03l0 206.3 -62.81 0 0 -199.17c0,-55.67 -20.7,-102.04 -79.94,-102.04 -41.41,0 -73.52,29.25 -84.23,64.22 -2.86,7.85 -4.29,18.57 -4.29,29.28l0 207.71 -62.81 0 0 -251.98z"
                  />
                  <path
                    class="fil1"
                    d="M1310.57 1271.69c0,127.78 -88.52,183.45 -172.03,183.45 -93.5,0 -165.59,-68.52 -165.59,-177.73 0,-115.62 75.65,-183.45 171.31,-183.45 99.2,0 166.31,72.1 166.31,177.73zm-274.09 3.58l0 0c0,75.66 43.53,132.74 104.92,132.74 59.96,0 104.92,-56.36 104.92,-134.18 0,-58.54 -29.27,-132.74 -103.49,-132.74 -74.24,0 -106.35,68.52 -106.35,134.18z"
                  />
                  <path
                    class="fil1"
                    d="M1409.13 1101.81l67.8 194.17c11.42,31.38 20.71,59.94 27.84,88.5l2.15 0c7.86,-28.56 17.85,-57.12 29.27,-88.5l67.09 -194.17 65.67 0 -135.62 345.48 -59.96 0 -131.33 -345.48 67.09 0z"
                  />
                  <path
                    class="fil1"
                    d="M1916.68 1447.29l-4.99 -43.55 -2.14 0c-19.27,27.11 -56.39,51.4 -105.64,51.4 -69.96,0 -105.65,-49.26 -105.65,-99.22 0,-83.51 74.25,-129.19 207.73,-128.47l0 -7.16c0,-28.53 -7.86,-79.93 -78.54,-79.93 -32.1,0 -65.66,9.99 -89.93,25.7l-14.26 -41.41c28.54,-18.57 69.95,-30.69 113.48,-30.69 105.64,0 131.34,72.1 131.34,141.35l0 129.19c0,29.97 1.43,59.22 5.71,82.79l-57.11 0zm-9.26 -176.32l0 0c-68.53,-1.42 -146.34,10.71 -146.34,77.82 0,40.69 27.12,59.94 59.24,59.94 44.98,0 73.52,-28.55 83.51,-57.8 2.16,-6.41 3.59,-13.58 3.59,-19.99l0 -59.97z"
                  />
                  <path
                    class="fil1"
                    d="M2142.28 1002.59l0 99.22 89.94 0 0 47.82 -89.94 0 0 186.31c0,42.82 12.15,67.08 47.12,67.08 16.41,0 28.56,-2.14 36.41,-4.28l2.84 47.13c-12.12,5 -31.4,8.55 -55.67,8.55 -29.27,0 -52.81,-9.27 -67.8,-26.39 -17.84,-18.57 -24.27,-49.27 -24.27,-89.95l0 -188.45 -53.54 0 0 -47.82 53.54 0 0 -82.78 61.37 -16.44z"
                  />
                  <path
                    class="fil1"
                    d="M2372.88 1004.73c0.71,21.43 -14.98,38.55 -39.98,38.55 -22.12,0 -37.83,-17.12 -37.83,-38.55 0,-22.12 16.42,-39.24 39.26,-39.24 23.57,0 38.55,17.12 38.55,39.24zm-69.95 442.56l0 0 0 -345.48 62.8 0 0 345.48 -62.8 0z"
                  />
                  <path
                    class="fil1"
                    d="M2494.25 1101.81l67.8 194.17c11.42,31.38 20.71,59.94 27.84,88.5l2.15 0c7.86,-28.56 17.85,-57.12 29.27,-88.5l67.09 -194.17 65.66 0 -135.61 345.48 -59.96 0 -131.33 -345.48 67.09 0z"
                  />
                  <path
                    class="fil1"
                    d="M2841.19 1285.99c1.43,84.92 55.68,119.89 118.49,119.89 44.99,0 72.1,-7.86 95.67,-17.85l10.68 44.99c-22.12,9.99 -59.95,21.4 -114.9,21.4 -106.36,0 -169.88,-69.94 -169.88,-174.15 0,-104.22 61.37,-186.31 162.03,-186.31 112.75,0 142.73,99.22 142.73,162.74 0,12.85 -1.42,22.84 -2.14,29.29l-242.68 0zm184.15 -45l0 0c0.72,-39.96 -16.4,-102.07 -87.06,-102.07 -63.53,0 -91.37,58.53 -96.37,102.07l183.43 0z"
                  />
                  <path
                    class="fil1"
                    d="M3390.86 1002.59l0 99.22 89.95 0 0 47.82 -89.95 0 0 186.31c0,42.82 12.16,67.08 47.13,67.08 16.4,0 28.56,-2.14 36.38,-4.28l2.86 47.13c-12.12,5 -31.38,8.55 -55.67,8.55 -29.25,0 -52.81,-9.27 -67.8,-26.39 -17.85,-18.57 -24.25,-49.27 -24.25,-89.95l0 -188.45 -53.54 0 0 -47.82 53.54 0 0 -82.78 61.35 -16.44z"
                  />
                  <path
                    class="fil1"
                    d="M3551.51 940.51l62.8 0 0 215.56 1.41 0c10,-17.84 25.71,-33.55 45,-44.27 18.53,-10.71 40.68,-17.84 64.21,-17.84 46.41,0 120.65,28.56 120.65,147.76l0 205.57 -62.8 0 0 -198.44c0,-55.67 -20.71,-102.77 -79.96,-102.77 -40.69,0 -72.8,28.53 -84.24,62.8 -3.54,8.58 -4.27,17.85 -4.27,29.98l0 208.43 -62.8 0 0 -506.78z"
                  />
                  <path
                    class="fil1"
                    d="M4017.66 1004.73c0.73,21.43 -14.98,38.55 -39.96,38.55 -22.12,0 -37.83,-17.12 -37.83,-38.55 0,-22.12 16.4,-39.24 39.24,-39.24 23.57,0 38.55,17.12 38.55,39.24zm-69.93 442.56l0 0 0 -345.48 62.8 0 0 345.48 -62.8 0z"
                  />
                  <path
                    class="fil1"
                    d="M4114.75 1195.31c0,-35.69 -0.73,-64.94 -2.86,-93.5l55.67 0 3.58 57.12 1.42 0c17.15,-32.83 57.12,-64.97 114.2,-64.97 47.85,0 122.06,28.56 122.06,147.03l0 206.3 -62.8 0 0 -199.17c0,-55.67 -20.71,-102.04 -79.93,-102.04 -41.41,0 -73.52,29.25 -84.23,64.22 -2.86,7.85 -4.31,18.57 -4.31,29.28l0 207.71 -62.8 0 0 -251.98z"
                  />
                  <path
                    class="fil1"
                    d="M4573.08 1260.29l1.41 0c8.58,-12.17 20.71,-27.15 30.7,-39.28l101.36 -119.2 75.65 0 -133.46 142.04 152.03 203.44 -76.38 0 -119.2 -165.61 -32.11 35.69 0 129.92 -62.11 0 0 -506.78 62.11 0 0 319.78z"
                  />
                  <path
                    class="fil1"
                    d="M4927.86 1004.73c0.72,21.43 -14.99,38.55 -39.96,38.55 -22.12,0 -37.83,-17.12 -37.83,-38.55 0,-22.12 16.4,-39.24 39.24,-39.24 23.56,0 38.55,17.12 38.55,39.24zm-69.94 442.56l0 0 0 -345.48 62.81 0 0 345.48 -62.81 0z"
                  />
                  <path
                    class="fil1"
                    d="M5024.98 1195.31c0,-35.69 -0.73,-64.94 -2.86,-93.5l55.67 0 3.55 57.12 1.44 0c17.13,-32.83 57.09,-64.97 114.21,-64.97 47.82,0 122.06,28.56 122.06,147.03l0 206.3 -62.81 0 0 -199.17c0,-55.67 -20.7,-102.04 -79.96,-102.04 -41.41,0 -73.51,29.25 -84.23,64.22 -2.86,7.85 -4.27,18.57 -4.27,29.28l0 207.71 -62.8 0 0 -251.98z"
                  />
                  <path
                    class="fil1"
                    d="M5718.82 1101.81c-1.45,24.98 -2.86,52.81 -2.86,94.95l0 200.57c0,79.2 -15.71,127.75 -49.27,157.75 -33.52,31.39 -82.06,41.38 -125.6,41.38 -41.41,0 -87.1,-9.99 -114.93,-28.56l15.71 -47.82c22.84,14.3 58.53,27.15 101.35,27.15 64.25,0 111.35,-33.56 111.35,-120.65l0 -38.55 -1.42 0c-19.29,32.14 -56.39,57.84 -109.93,57.84 -85.64,0 -147.04,-72.83 -147.04,-168.46 0,-117.07 76.38,-183.45 155.62,-183.45 59.94,0 92.78,31.42 107.76,59.98l1.45 0 2.86 -52.13 54.95 0zm-64.94 136.32l0 0c0,-10.68 -0.73,-19.98 -3.59,-28.52 -11.43,-36.42 -42.09,-66.39 -87.78,-66.39 -59.98,0 -102.8,50.68 -102.8,130.61 0,67.83 34.28,124.19 102.08,124.19 38.55,0 73.52,-24.25 87.06,-64.22 3.58,-10.71 5.03,-22.84 5.03,-33.55l0 -62.12z"
                  />
                </g>
              </g>
            </svg>
          </div>

          <div class="intro_logo_text">Ключевой элемент к вашему успеху</div>
        </div>

        <div
          class="intro_item intro_info"
          v-bind:class="{ showItem: showInfo }"
        >
          <p class="intro_title">Добро пожаловать в ГринХеми</p>
          <p class="intro_text">Поставщик качественного химического сырья</p>
          <p class="intro_text">
            Наша компания поставляет качественное химическое сырье для
            косметической отрасли, дорожного строительства и индустриальной
            промышленности.
          </p>
          <p class="intro_text">
            Основное преимущество компании – глубокие знания в области химии,
            современных технических процессах, а также большой опыт продаж.
          </p>
          <p class="intro_text">
            На протяжении 10 лет наша миссия остается прежней: мы устанавливаем
            долгосрочные отношения с нашими клиентами, обеспечивая качество,
            инновации и сервис.
          </p>
        </div>
      </div>
    </div>
    <div class="activity">
      <div class="activity_item">
        <h3 class="activity_title">Качество</h3>
        <p class="activity_text">
          ГринХеми уделяет большое внимание качеству и безопасности
          поставляемого химического сырья. Вся продукция сертифицирована в
          соответствии с международными стандартами: REACH, ECOCERT, COSMOS.
        </p>
      </div>
      <div class="activity_item">
        <h3 class="activity_title">Инновации</h3>
        <p class="activity_text">
          ГринХеми реализует товары, созданные по современным технологиям.
          Оказывает помощь потребителям по разработке и внедрению в производство
          современных рецептур.
        </p>
      </div>
      <div class="activity_item">
        <h3 class="activity_title">Сервис</h3>
        <p class="activity_text">
          ГринХеми обеспечивает быстрое, качественное, вежливое обслуживание от
          заказа товара до его последующей поставки. Полное удовлетворение
          потребностей заказчика - наш главный приоритет.
        </p>
      </div>
      <div class="activity_item">
        <h3 class="activity_title">Снижение себестоимости</h3>
        <p class="activity_text">
          Будет ли это конкурентное ценообразование, сотрудничество в области
          разработок рецептур или обеспечение эксклюзивных ингредиентов,
          ГринХеми оказывает поддержку и помощь в снижении производственных
          затрат.
        </p>
      </div>
    </div>
    <the-carousel-section></the-carousel-section>
  </div>
</template>


<script>
export default {
  data() {
    return {
      hideLogo: false,
      showInfo: false,
    };
  },
  methods: {},
  mounted() {
    setTimeout(() => {
      this.hideLogo = true;
      setTimeout(() => {
        this.showInfo = true;
      }, 500);
    }, 800);
  },
};
</script>

<style scoped>
.background_intro {
  background-image: linear-gradient(
      to top,
      rgba(0, 132, 89, 1),
      rgba(0, 132, 89, 0.8)
    ),
    url(../assets/background.png);
  background-size: cover;
  position: relative;
  height: 110vh;
  width: 100%;
}
.polygon {
  clip-path: polygon(0 0, 100% 0%, 100% 91%, 0 100%, 0 100%);
}
/* INTRO */
.intro {
  width: 90rem;
  height: 100vh;
  margin-left: auto;
  margin-right: auto;
  padding-top: 11rem;
  color: #ffffff;
  position: relative;
}
.intro_item {
  position: absolute;
}
.intro_logo {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
}
.intro_img {
  width: 150px;
  height: 150px;
  background-color: transparent;
  position: relative;
  justify-self: left;
}
.intro_img svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: auto;
}
.logo_text {
  align-self: center;
  display: flex;
  justify-content: right;
}
.intro_logo_text {
  color: white;
  letter-spacing: 2px;
  font-size: 2.2rem;
  font-weight: 500;
  line-height: 1.1;
  grid-column: 1/3;
  text-align: center;
  width: auto;
}
.intro_info {
  opacity: 0;
  padding-top: 15rem;
}
.hideItem {
  opacity: 0;
  transition: all 0.5s linear;
}
.showItem {
  opacity: 1;
  transition: all 2s linear;
}
.intro_title {
  font-family: "Roboto", sans-serif;
  font-size: 3rem;
  line-height: 1.1px;
  text-align: center;
  margin-bottom: 2rem;
  text-transform: uppercase;
  
}
.intro_text {
  font-family: "Roboto", sans-serif;
  font-size: 1.9rem;
  letter-spacing: 1px;
  line-height: 1.5;
  color: #ffffff;
  text-align: center;
  margin-bottom: 2rem;
}
/* ACTIVITY Card of Activities*/
.activity {
  position: sticky;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
  justify-content: center;
  max-width: 85%;
  margin-left: auto;
  margin-right: auto;
  margin-top: -100px;
}
.activity_item {
  width: 20vw;
  background-color: #008459;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.2) inset;
  transition: all 0.4s ease-out;
}
.activity_item:hover {
  transform: translate(0, -15px);
}
.activity_title {
  font-size: 2.6rem;
  font-weight: 400;
  color: white;

  text-align: center;
  text-transform: uppercase;
  padding: 22px 0 10px 0;
}
.activity_text {
  font-size: 2.4rem;
  font-weight: 300;
  color: white;
  line-height: 30px;
  text-align: center;
  padding: 20px;
}
.svg_text {
  width: 300px;
  background-color: transparent;
}
.fil0 {
  fill: #fefefe;
  fill-rule: nonzero;
}
.fil1 {
  fill: #fefefe;
  fill-rule: nonzero;
}

/* Media Queries  for IntroSection */
/* Media Queries 1350px */
@media (max-width: 1350px) {
  .activity_title {
    font-size: 2.2rem;
  }
  .activity_text {
    font-size: 2rem;
  }
}
/* Media Queries 1200px */
@media (max-width: 1200px) {
  .background_intro {
    width: 100%;
  }
  .intro {
    width: 100%;
    justify-content: center;
  }
  .intro_item {
    padding: 0 15px;
  }
  .intro_info {
    padding-top: 10rem;
    width: 80vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .intro_logo {
    width: 60vw;
  }
  .intro_title {
    font-size: 2.6rem;
  }
  .activity {
    grid-template-columns: 1fr 1fr;
    gap: 25px;
  }
  .activity_item {
    width: 45vw;
  }
  .activity_title {
    font-size: 2.4rem;
  }
  .activity_text {
    font-size: 2rem;
  }
}
/* Media Queries 992px */
@media (max-width: 992px) {
  .intro_info {
    padding-top: 8rem;
    width: 75vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .intro_logo {
    width: 65vw;
  }

  .intro_logo_text {
    font-size: 2.8rem;
  }
  .intro_title {
    font-size: 2.2rem;
  }
}
/* Media Queries 768px */
@media (max-width: 768px) {
  .background_intro {
    width: 100%;
  }
  .intro {
    width: 100%;
    justify-content: center;
  }
  .intro_item {
    padding: 0 15px;
  }
  .intro_info {
    padding-top: 6rem;
    width: 90vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .intro_title {
    font-size: 2rem;
  }
  .intro_text {
    font-size: 1.8rem;
  }
  .activity {
    grid-template-columns: 1fr;
  }
  .activity_item {
    width: 90vw;
  }
  .activity_title {
    font-size: 2rem;
  }
  .activity_text {
    font-size: 1.8rem;
  }
  .intro_img {
    width: 100px;
    height: auto;
    margin-bottom: 10px;
  }
  .intro_img svg {
    width: 100px;
  }
  .intro_logo_text {
    font-size: 2rem;
  }
  .logo_text {
    margin-bottom: 10px;
  }
  .logo_text svg {
    width: 150px;
  }
}
/* Media Queries 500px */
@media (max-width: 500px) {
  .intro_logo {
    width: 85vw;
  }
  .intro_info {
    width: 95vw;
  }
  .intro_title {
    font-size: 1.7rem;
  }
  .intro_text {
    font-size: 1.6rem;
  }
}
/* Media Queries 350px */
@media (max-width: 350px) {
  .intro_title {
    font-size: 1.4rem;
  }
  .intro_text {
    font-size: 1.2rem;
  }
}
</style>