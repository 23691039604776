<template>
  <the-base-product-list-style>
    <template v-slot: product></template>
    <template v-slot:product_header>
      <h3></h3>
    </template>
    <template v-slot:product_title>
      <h5>Ферментативные масла</h5>
    </template>
    <template v-slot:product_list>
      <!-- Fermentoil Avocado -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Fermentoil Avocado</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI : Candida Bombicola/Glucose/Methyl Rapeseedate Ferment, Persea
            Gratissima (Avocado) Oil
          </p>
        </template>
      </the-base-product-item-style>
      <!-- Fermentoil Angelica -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Fermentoil Angelica</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI : Pseudozyma Epicola/Apricot Kernel Oil/Olive Fruit
            Oil/Sunflower Seed Oil/Sweet Almond Oil/ Angelica Gigas Root Extract
            Ferment Extract Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- Fermentoil Argan -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Fermentoil Argan</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI : Pseudozyma Epicola/Argania Spinosa Kernel Oil Ferment
            Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- Fermentoil Artemisia -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Fermentoil Artemisia</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI : Pseudozyma epicola / Apricot Kernel Oil/Olive Fruit Oil/Sweet
            Almond Oil/Sunflower Seed Oil/Artemisia Princeps Extract Ferment
            Extract Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- Fermentoil Camellia seed -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Fermentoil Camellia seed</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI : Pseudozyma Epicola/Camellia Japonica Seed Oil Ferment Extract
            Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- Fermentoil Evening primrose -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Fermentoil Evening primrose</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI : Candida Bombicola/Glucose/Methyl Rapeseedate Ferment,
            Oenothera Biennis (Evening Primrose) Oil
          </p>
        </template>
      </the-base-product-item-style>
      <!-- Fermentoil Green tea seed -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Fermentoil Green tea seed</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI : Pseudozyma Epicola/Camellia Sinensis Seed Oil Ferment Extract
            Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- Fermentoil Glycyrrhiza -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Fermentoil Glycyrrhiza</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI : Pseudozyma epicola / Apricot Kernel Oil/Olive Fruit Oil/Sweet
            Almond Oil/Sunflower Seed Oil/Licorice Root Extract Ferment Extract
            Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- Fermentoil Olive -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Fermentoil Olive</h6>
        </template>
        <template v-slot:product_text>
          <p>INCI : Pseudozyma Epicola/Olive Fruit Oil Ferment Filtrate</p>
        </template>
      </the-base-product-item-style>
      <!-- Fermentoil Red Ginseng -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Fermentoil Red Ginseng</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI : Pseudozyma epicola/Apricot Kernel Oil/Olive Fruit
            Oil/Sunflower Seed Oil/Sweet Almond Oil/Panax Ginseng Root Extract
            Ferment Extract Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- Fermentoil Safflower seed -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Fermentoil Safflower seed</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI : Pseudozyma Epicola/Safflower Seed Oil Ferment Extract
            Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- Fermentoil Sunflower seed -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Fermentoil Sunflower seed</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI : Pseudozyma Epicola/Sunflower Seed Oil Ferment Extract
            Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- Fermentoil Saururus -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Fermentoil Saururus</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI : Pseudozyma Epicola/Apricot Kernel Oil/Olive Fruit
            Oil/Sunflower Seed Oil/Sweet Almond Oil/Saururus Chinensis Extract
            Ferment Extract Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- Fermentoil Shiunko -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Fermentoil Shiunko</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI : Pseudozyma epicola /Apricot Kernel Oil/Olive Fruit
            Oil/Sunflower Seed Oil/Sweet Almond Oil/(Angelica Gigas/Lithospermum
            Erythrorhizon) Root Extract Ferment Extract Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- Fermentoil Complex -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Fermentoil Complex</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI : Pseudozyma epicola/Olive Fruit Oil/Sunflower Seed Oil/Argania
            Spinosa Kernel Oil/(Angelica Gigas/Lithospermum Erythrorhizon) Root
            Extract Ferment Extract Filtrate/Licorice Root Extract Ferment
            Extract Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- Fermentoil Hair Complex -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Fermentoil Hair Complex</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI : Pseudozyma epicola /Prunus Amygdalus Dulcis (Sweet Almond)
            Oil/Camellia Japonica Seed Oil/Argania Spinosa Kernel Oil/Helianthus
            Annuus (Sunflower) Seed Oil/Camellia Sinensis Seed Oil
          </p>
        </template>
      </the-base-product-item-style>
    </template>
  </the-base-product-list-style>
</template>
