<template>
  <div class="background_carousel">
    <carousel
      class="carousel_section container"
      :items-to-show="1"
      :autoplay="2500"
      :wrap-around="true"
    >
      <slide v-for="slide in carouselObject" :key="slide.id">
        <div class="carousel_item">
          <div class="carousel_img">
            <img class="img" :src="slide.image" loading="lazy" alt="image" />
          </div>
          <div class="carousel_text">
            <h2>{{ slide.title }}</h2>
            <p>{{ slide.content }}</p>
          </div>
        </div>
      </slide>
      <template #addons>
        <navigation />
        <pagination />
      </template>
    </carousel>
  </div>
</template>

<script>
import { Carousel, Slide, Navigation, Pagination } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import "../styles/main.css";
export default {
  components: {
    Carousel,
    Slide,
    Navigation,
    Pagination,
  },
  data() {
    return {
      itemsToShow: {
        default: 1,
        type: Number,
      },
      itemsToScroll: {
        default: 1,
        type: Number,
      },
      wrapAround: {
        default: true,
        type: Boolean,
      },
      transition: {
        default: 10,
        type: Number,
      },
      settings: {
        default() {
          return {};
        },
        type: Object,
      },
      breakpoints: {
        default: null,
        type: Object,
      },
      autoplay: {
        default: 10,
        type: Number,
      },
      modelValue: {
        default: 0,
        type: Number,
      },
      mouseDrag: {
        default: true,
        type: Boolean,
      },
      touchDrag: {
        default: true,
        type: Boolean,
      },
      carouselObject: [
        {
          id: "1",
          title: "Дорожная отрасль",
          content:
            "Благодаря обширным контактам среди европейских и азиатских производителей химического сырья для различных индустрий промышленности, мы имеем возможность подобрать нужное сырье, оказать техническую поддержку в разработке новых рецептур или замене сырья в существующих рецептурах с целью снижения  себестоимости продукции.",
          image: require("@/assets/road_section.jpg"),
        },
        {
          id: "2",
          title: "Индустриальная отрасль",
          content:
            "Благодаря обширным контактам среди европейских и азиатских производителей химического сырья для различных индустрий промышленности, мы имеем возможность подобрать нужное сырье, оказать техническую поддержку в разработке новых рецептур или замене сырья в существующих рецептурах с целью снижения  себестоимости продукции.",
          image: require("@/assets/chemical_industry.jpg"),
        },
        {
          id: "3",
          title: "Косметическая отрасль",
          content:
            "Благодаря обширным контактам среди европейских и азиатских производителей химического сырья для различных индустрий промышленности, мы имеем возможность подобрать нужное сырье, оказать техническую поддержку в разработке новых рецептур или замене сырья в существующих рецептурах с целью снижения  себестоимости продукции.",
          image: require("@/assets/cosmetic_industry.jpg"),
        },
      ],
    };
  },
};
</script>

<style scoped>
.background_carousel {
  background-color: rgba(0, 132, 89, 1);
  height: auto;
}
.carousel_section {
  margin-top: 90px;
  max-width: 1400px;
  height: 35rem;
  margin-left: auto;
  margin-right: auto;
}
.carousel_item {
  display: grid;
  grid-template-columns: 0.3fr 0.7fr;
  gap: 20px;
  align-items: center;
  color: white;
  font-size: 1.8rem;
  padding: 50px 50px;
}
.carousel_img {
  width: 370px;
  height: 250px;
  overflow: hidden;
}
img {
  width: 420px;
  height: auto;
  justify-self: end;
}
.carousel_text h2 {
  margin-bottom: 30px;
}
.carousel__pagination {
  padding-left: 485px;
  margin: -40px 0 0;
}
.carousel__icon {
  color: white;
}

/* Media Queries 998px */
@media (max-width: 998px) {
  .carousel__pagination {
    padding-left: 0;
  }
  .carousel_item {
    grid-template-columns: 1fr;
  }
  .carousel_img {
    margin-right: auto;
    margin-left: auto;
  }
  .carousel_section {
    height: auto;
  }
  .carousel_text h2 {
    font-size: 2.4rem;
  }
}

.carousel__pagination {
  padding-bottom: 20px;
}
/* Media Queries 747px */
@media (max-width: 747px) {
  .carousel__pagination {
    padding-left: 0;
  }
  .carousel_text {
    padding: 10px;
  }
}
/* Media Queries 500px */
@media (max-width: 500px) {
  .img {
    width: 80%;
    height: 80%;
  }
  .carousel_text {
    padding: 15px;
  }
  
}
</style>