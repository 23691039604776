<template>
  <the-base-product-list-style>
    <template v-slot: product></template>
    <template v-slot:product_header>
      <h3>Пептиды</h3>
    </template>
    <template v-slot:product_title>
      <h5></h5>
    </template>
    <template v-slot:product_list>
      <!-- SpecPed® GHK-Cu -->
      <the-base-product-item-style
        v-for="PeptideObject in PeptideObjects"
        v-bind:key="PeptideObject.id"
      >
        <template v-slot:product_subtitle>
          <h6>
            {{ PeptideObject.name }}
          </h6>
        </template>
        <template v-slot:product_text>
          <p>
            {{ PeptideObject.chemicalName }}
          </p>
          <button v-on:click="addInfo(PeptideObject.id)" class="btn_addInfo">
            Дополнительная информация
          </button>
          <div
            v-if="showAddInfo === PeptideObject.id"
            class="background_addInfo"
          >
            <p class="addInfo">
              <button v-on:click="hideAddInfo" class="btn_hide">Закрыть</button>
              {{ PeptideObject.description }}
            </p>
          </div>
        </template>
      </the-base-product-item-style>
    </template>
  </the-base-product-list-style>
</template>





<script>
export default {
  data() {
    return {
      showAddInfo: -1,
      PeptideObjects: [
        {
          id: 1,
          name: "SpecPed® GHK-Cu",
          chemicalName:
            "INCI: Copper tripeptide-1 & Glycerin & Aqua&Caprylyl Glycol & Ethylhexylglycerin",
          description:
            "SpecPed® GHK-Cu (Copper Peptide), a naturally occurring human tripeptide, can accelerate degradation of supersized collagen and promote synthesis of regular collagen, elastin, proteoglycans and glycosaminoglycans. It possesses various properties, such as skin repairing, skin-remolding, anti-aging anti-inflammatory, antioxidant.",
        },
        {
          id: 2,
          name: "SpecPed® GHK2-Cu",
          chemicalName:
            "INCI: Copper tripeptide-1/Bis(tripeptide-1) copper acetate & Glycerin & Aqua &Caprylyl Glycol &Ethylhexylglycerin",
          description:
            "Copper peptide is a signal and carrier peptide. GHK is primarily known as a carrier peptide. It mainly helps to stabilize and deliver Cu. It is also a signal peptide that promotes extra-large collagen aggregates degradation in scars, regular collagen synthesis in normal skin, elastin, proteoglycans and glycosaminoglycans production, growth rate and migration of different cell types and anti-inflammatory and antioxidant responses.",
        },
        {
          id: 3,
          name: "SpecPed® 3AK",
          chemicalName:
            "INCI: Palmitoyl Tripeptide-1 & Palmitoyl Tetrapeptide-7 & Aqua & Glycerin & Butylene Glycol & Carbomer & Polysorbate 20 & Carylyl Glycol&Ethylhexylglycerin",
          description:
            "The active ingredients of SpecPed® 3AK are Palmitoyl Tripeptide-1 and Palmitoyl Tetrapeptide-7. The former is a Matrikine signal peptide, acting on the dermis, can promote the synthesis of extracellular collagen, elastin and laminin, can promote wound recovery and tighten the skin, ease wrinkles. The latter has many bioactive functions, especially immunomodulatory functions. Studies have shown that palmityl tetrapeptide-7 can reduce inflammatory cytokines in skin cells and plays an important role in delaying skin aging. Importantly, this two peptide can show perfect synergistic reaction on promoting and composing Collagen I, Fibrinogen, and Hyaluronic Acid. Therefore, SpecPed® 3AK can be used in all kinds of anti-aging and eye care products. It can effectively restore and prevent wrinkle producing.",
        },
        {
          id: 4,
          name: "SpecPed® BT1 Hair Growth",
          chemicalName:
            "INCI: Biotinoyl Tripeptide-1 & Apigenin & Oleanolic acid & Butylene Glycol & Aqua & PPG-26-Buteth-26 & PEG-40 Hydrogenated Castor Oil",
          description:
            "BT1 Hair Growth combines three natural ingredients to increase hair anchoring. These are biotinoyl-GHK with a flavonoid from citrus, and oleanic acid from olive trees. It increases your scalps production of cellular binding elements which blocks the effects of DHT. It increases blood stimulation within the scalp, allowing more nourishment to reach the dermal papilla cells, which will produce healthier rowing hair.",
        },
        {
          id: 5,
          name: "SpecPed® Sensi AD",
          chemicalName:
            "INCI: Acetyl dipeptide-1 cetyl ester, Butylene Glycol, Aqua, Sorbitan Laurate, Hydroxyethylcellulose",
          description:
            "SpecPed® Sensi AD can reduce the perception of irritation, such as pain and discomfort (sensitive, irritated, dry or pain skin); increase the skin’s tolerance to internal and external aggressions (heat and cold); relieve stressed skin and improve skin laxity; repair the mildly sensitive skin. Thus, SpecPed® Sensi AD can be recommended for sensitive skin care products, sunscreen, post-sun care products.",
        },
        {
          id: 6,
          name: "SpecPed® SC-PT38",
          chemicalName:
            "INCI: Palmitoyl Tripeptide-38, Aqua, Glycerin, Hydroxypropyl Cyclodextrin, Caprylyl Glycol &Ethylhexylglycerin, Trehalose",
          description:
            "SpecPed® SC-PT38 with active component-palmitoyl tripeptide-38 can promote the synthesis of collagen I, III, IV, fibronectin, hyaluronic acid and laminin which are the main components of the skin matrix and the epidermal-dermal junction, reconstructing the skin network, smoothing wrinkles and moisturizing skin.",
        },
        {
          id: 7,
          name: "SpecPed® PluLip",
          chemicalName:
            "INCI: Palmitoyl Tripeptide-1, Ethylhexyl Palmitate, Tribehenin, Sorbitan Stearate",
          description:
            "SpecPed® PluLip, the main active substance is Palmitoyl Tripeptide-1, it is a product obtained by the reaction of palmitic acid with a synthetic tripeptide consisting of the following amino acids: glycine, histidine, lysine. It acts as a messenger of cutaneous re-structuration and repair, and works synergistically to restore and maintain the skin’s youthful appearance. Thus, our product “SpecPed® PluLip (0.1%)” is useful in anti-aging products and wrinkle treatment products such as gels, serums, lotions and creams",
        },
        {
          id: 8,
          name: "SpecPed® P4T7",
          chemicalName:
            "INCI: Palmitoyl Tetrapeptide-7, Butylene Glycol, Glycerin, Carbomer, Polysorbate 20, Aqua, Caprylyl Glycol & Ethylhexylglycerin",
          description:
            "SpecPed® P4T7P is Palmitoyl Tetrapeptide-7, It can delay and inhibit the production of excessive interleukin, so as to inhibit some unnecessary and inappropriate inflammatory response and glycosylation damage. It acts on keratinocytes and fibroblasts to inhibit the release of inflammatory cytokines, reduce the damage of inflammation to the skin, prevent the deepening of wrinkles and tighten the skin. Therefore, SpecPed® P4T7P can be recommended to use as the function of anti-aging, anti-wrinkling, anti-inflammatory in cosmetics.",
        },
        {
          id: 9,
          name: "SpecPed® SC-PP18",
          chemicalName:
            "INCI: Pentapeptide-18, Glycerin, Aqua,Caprylyl Glycol &Ethylhexylglycerin",
          description:
            "SpecPed® SC-PP18 (0.05%) contains an active peptide called Pentapeptide-18. It keeps Na+/Ca2+ outside the cell and keeps K+ leaking to the outside of the cell, making the neuron cell at rest. So it can relax muscles and prevent wrinkles from forming. Therefore, SpecPed® SC-PP18（0.05%) can be used as a functional ingredient to be added to products that claim to be anti-wrinkle and anti-aging.",
        },
        {
          id: 10,
          name: "SpecPed® SC-AO3",
          chemicalName:
            "INCI: Acetyl Octapeptide-3, Aqua, Caprylyl Glycol& Ethylhexylglycerin",
          description:
            "The active ingredient of SpecPed® SC-AO3 (0.05%) is Acetyl Octapeptide-3. It has functions as below: “Botox-like” peptide but safer without side-effects. It’s an analogue of the N-terminal end of SNAP-25 that competes for a position in the SNARE complex, which is essential for muscle contraction. It can effectively relieve muscle contractions by inhibit the SNARE complex formation and acetylcholine release, thus can reduce wrinkles dramatically. Therefore, SpecPed® SC-AO3 (0.05%) can be recommended to use as functions of anti-wrinkles, anti-aging in cosmetics.",
        },
        {
          id: 11,
          name: "SpecPed® SC-AH8",
          chemicalName:
            "INCI: Acetyl hexapeptide-8, Glycerin, Aqua, Caprylyl Glycol &Ethylhexylglycerin",
          description:
            "SpecPed® SC-AH8(0.05%) is a highly potent neurotransmitter inhibitory peptide. Its structure is similar to the N-terminal of SNAP-25, and it can compete for binding sites that inhibit the release of neurotransmitters and slow down muscle contractions, ultimately smoothing skin and improving fine lines. Therefore, SpecPed® SC-AH8(0.05%) can be added to products that claim to slow aging and smooth the skin.",
        },
      ],
    };
  },
  methods: {
    addInfo(param) {
      if (param) {
        this.showAddInfo = param;
        console.log(param);
        console.log(this.showAddInfo);
      }
    },
    hideAddInfo() {
      this.showAddInfo = -1;
    },
  },
};
</script>


<style scoped>
.background_addInfo {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.833);
}
.addInfo {
  width: 40vw;
  height: auto;
  position: absolute;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid;
  background-color: white;
  color: rgba(0, 132, 89, 1);
  transition: all 0.5s linear;
  text-align: justify;
  padding: 40px 20px 20px 20px;
  box-shadow: 4px 4px 4px 0px #99cebd;
  overflow: auto;
}
.btn_addInfo {
  background-color: rgba(0, 132, 89, 1);
  color: white;
  border: none;
  padding: 5px 10px 5px 10px;
  border-radius: 6px 0px 6px 0px;
  margin-top: 10px;
}
.btn_addInfo:hover {
  box-shadow: 4px 4px 4px 0px rgb(254, 255, 255, 0.4);
  background-color: #e7ebe9;
}

.btn_addInfo:active {
  box-shadow: 0 0.5em 0.5em -0.4em;
  transform: translate(0.25em, 0.25em);
}
.btn_hide {
  position: absolute;
  right: 5px;
  top: 5px;
  background-color: rgba(0, 132, 89, 1);
  color: white;
  border: none;
  padding: 5px 10px 5px 10px;
  border-radius: 6px 0px 6px 0px;
}
.btn_hide:hover {
  background-color: #99cebd;
  box-shadow: 4px 4px 4px 0px #415c4e;
}
.btn_hide:active {
  box-shadow: 0 0.5em 0.5em -0.4em;
  transform: translate(0.25em, 0.25em);
}

/* Media Queries */
@media (max-width: 1350px) {
}
/* Media Queries (max-width: 1250px) */
@media (max-width: 1250px) {
}
/* Media Queries (max-width: 1200px) */
@media (max-width: 1200px) {
}
/* Media Queries (max-width: 998px) */
@media (max-width: 998px) {
  .addInfo {
    width: 80vw;
  }
}

/* Media Queries (max-width: 747px) */
@media (max-width: 747px) {
  .addInfo {
    width: 85vw;
  }
}
/* Media Queries (max-width: 500px) */
@media (max-width: 500px) {
  .addInfo {
    width: 95vw;
  }
}
</style>
