<template>
    <div class="heptagon">
        <div class="heptagon_markup">
          <div class="heptagon_item heptagon_item1 heptagon_animation1"></div>
          <div class="heptagon_item heptagon_item2 heptagon_animation2"></div>
          <div class="heptagon_item heptagon_item3 heptagon_animation3"></div>
        </div>
      </div>
</template>

<style scoped>
.heptagon {
  display: block;
  flex-direction: row;
  width: 900px;
  height: 100vh;
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
}
.heptagon_markup {
  position: relative;
}
.heptagon_item {
  background-color: #339d7a;
  clip-path: polygon(
    50% 0%,
    90% 20%,
    100% 60%,
    75% 100%,
    25% 100%,
    0% 60%,
    10% 20%
  );
  opacity: 0.3;
}
.heptagon_item1 {
  position: absolute;
  z-index: 100;
  width: 130px;
  height: 130px;
  top: 450px;
  left: 350px;
}
.heptagon_item2 {
  position: absolute;
  z-index: 200;
  width: 160px;
  height: 160px;
  top: 400px;
  left: 150px;
}
.heptagon_item3 {
  position: absolute;
  z-index: 300;
  width: 90px;
  height: 90px;
  top: 300px;
  left: 100px;
}
.heptagon_animation1 {
  animation-name: heptagonAnime1;
  animation-duration: 15s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.heptagon_animation2 {
  animation-name: heptagonAnime2;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.heptagon_animation3 {
  animation-name: heptagonAnime3;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes heptagonAnime1 {
  0% {
    transform: translateY(0px);
  }
  10% {
    transform: translateY(15px);
  }
  20% {
    transform: translateY(35px);
  }
  30% {
    transform: translateY(55px);
  }
  40% {
    transform: translateY(65px);
  }
  50% {
    transform: translateY(70px);
  }
  60% {
    transform: translateY(65px);
  }
  70% {
    transform: translateY(55px);
  }
  80% {
    transform: translateY(35px);
  }
  90% {
    transform: translateY(15px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes heptagonAnime2 {
  0% {
    transform: translateY(0px);
  }
  10% {
    transform: translateY(-15px);
  }
  20% {
    transform: translateY(-20px);
  }
  30% {
    transform: translateY(-40px);
  }
  40% {
    transform: translateY(-50px);
  }
  50% {
    transform: translateY(-60px);
  }
  60% {
    transform: translateY(-50px);
  }
  70% {
    transform: translateY(-40px);
  }
  80% {
    transform: translateY(-20px);
  }
  90% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes heptagonAnime3 {
  0% {
    transform: translateY(0px);
  }
  10% {
    transform: translateY(18px);
  }
  20% {
    transform: translateY(38px);
  }
  30% {
    transform: translateY(55px);
  }
  40% {
    transform: translateY(75px);
  }
  50% {
    transform: translateY(95px);
  }
  60% {
    transform: translateY(100px);
  }
  70% {
    transform: translateY(75px);
  }
  80% {
    transform: translateY(38px);
  }
  90% {
    transform: translateY(18px);
  }
  100% {
    transform: translateY(0px);
  }
}


/* Media Queries 992px */
@media (max-width: 992px) {
  .heptagon {
    max-width: 100%;
  }
  .heptagon_item1 {
  position: absolute;
  z-index: 100;
  width: 80px;
  height: 80px;
  top: 250px;
  left: 150px;
}
.heptagon_item2 {
  position: absolute;
  z-index: 200;
  width: 90px;
  height: 90px;
  top: 200px;
  left: 90px;
}
.heptagon_item3 {
  position: absolute;
  z-index: 300;
  width: 50px;
  height: 50px;
  top: 150px;
  left: 70px;
}
}
</style>