<template>
  <div class="background">
    <div class="trans_info_container container">
      <div class="transport_list">
        <div class="transport_information">
          <div class="trans_info_item">
            <p>Дата</p>
            <input class="input_style" type="date" v-model="enteredDate" />
          </div>
          <div class="trans_info_item">
            <p>Наименование</p>
            <input class="input_style" type="text" v-model="enteredName" />
          </div>
          <div class="trans_info_item">
            <p>Код товара</p>
            <input class="input_style" type="text" v-model="enteredGoodsCode" />
          </div>
          <div class="trans_info_item">
            <p>Условия поставки</p>
            <input
              class="input_style"
              type="text"
              v-model="enteredDeliveryCondition"
            />
          </div>
          <div class="trans_info_item">
            <p>Вес нетто, кг</p>
            <input class="input_style" type="text" v-model="enteredNetWeight" />
          </div>
        </div>
        <div class="transport_information">
          <div class="trans_info_item">
            <p>Вес брутто, кг</p>
            <input
              class="input_style"
              type="text"
              v-model="enteredBrutWeight"
            />
          </div>
          <div class="trans_info_item">
            <p>Грузовые места</p>
            <input
              class="input_style"
              type="text"
              v-model="enteredCargoPlaces"
            />
          </div>
          <div class="trans_info_item">
            <p>Размер грузовых мест</p>
            <input class="input_style" type="text" v-model="enteredSizeCargo" />
          </div>
          <div class="trans_info_item">
            <p>Прикрепить SDS/MSDS</p>
            <input
              class="input_style"
              v-on:change="enteredAddFile"
              type="file"
            />
          </div>
          <div class="trans_info_item">
            <p>Ставка транспортных расходов</p>
            <input class="input_style" type="text" v-model="enteredRateValue" />
            <select v-model="enteredCurrencyType" name="currencyType" id="">
              <option v-bind="EUR" value="EUR">EUR</option>
              <option value="USD">USD</option>
              <option value="RUB">RUB</option>
              <option value="BYN">BYN</option>
              <option value="CNY">CNY</option>
            </select>
            <select v-model="enteredTransportCompany" name="companyType" id="">
              <option value="RTL">RTL</option>
              <option value="STA">STA</option>
              <option value="Supplines">Supplines</option>
            </select>
          </div>
        </div>
        <button class="btn" v-on:click="addInformation">Добавить</button>
      </div>
    </div>
    <div class="table_section container">
      <table-information
        v-for="storageInfo in storageInfos"
        v-bind:key="storageInfo.id"
        v-bind:id="storageInfo.id"
        v-bind:rate="storageInfo.rate"
        v-bind:dateRate="storageInfo.dateRate"
        v-bind:nameGoods="storageInfo.nameGoods"
        v-bind:productCode="storageInfo.productCode"
        v-bind:deliveryConditions="storageInfo.deliveryConditions"
        v-bind:weightNetto="storageInfo.weightNetto"
        v-bind:weightBrutto="storageInfo.weightBrutto"
        v-bind:cargoPlaces="storageInfo.cargoPlaces"
        v-bind:sizeCargo="storageInfo.sizeCargo"
        v-bind:transportRate="storageInfo.transportRate"
        v-bind:currencyRate="storageInfo.currencyRate"
        v-bind:currencyType="storageInfo.currencyType"
        v-bind:transportCompany="storageInfo.transportCompany"
        v-bind:addFile="storageInfo.addFile"
        v-on:deleteTransport="deleteTransportInfo"
      ></table-information>
    </div>
  </div>
</template>


<script>
import TableInformation from "./TableInformation.vue";

export default {
  components: { TableInformation },
  data() {
    return {
      countRate: 1,
      storageInfos: [
        {
          rate: 1,
          id: Math.round(Math.random() * 1000000),
          dateRate: "13.03.2023",
          nameGoods: "Asfier 210",
          productCode: "3402420090",
          deliveryConditions: "EXW Barselona",
          weightNetto: "2500",
          weightBrutto: "3600",
          cargoPlaces: "6",
          sizeCargo: "1.15*1.15*1.5",
          currencyRate: "3500",
          currencyType: "EURO",
          transportCompany: "RTL",
        },
      ],
    };
  },
 
  methods: {
    addInformation() {
      const storageObject = {
        rate: this.countRate,
        id: Math.round(Math.random() * 1000000),
        dateRate: this.enteredDate,
        nameGoods: this.enteredName,
        productCode: this.enteredGoodsCode,
        deliveryConditions: this.enteredDeliveryCondition,
        weightNetto: this.enteredNetWeight,
        weightBrutto: this.enteredBrutWeight,
        cargoPlaces: this.enteredCargoPlaces,
        sizeCargo: this.enteredSizeCargo,
        currencyRate: this.enteredRateValue,
        currencyType: this.enteredCurrencyType,
        transportCompany: this.enteredTransportCompany,
      };

      this.storageInfos.push(storageObject);
      this.storageInfos.forEach((value, index)=>{
        this.countRate = index+1;
        console.log(value);
        console.log(index);
      })
      
      
    },
    deleteTransportInfo(deleteId) {
      console.log(deleteId);
      const newArray = this.storageInfos.filter((el) => el.id !== deleteId);
      console.log(newArray);
      this.storageInfos = newArray;
      console.log(this.storageInfos);
      this.storageInfos.forEach((value, index)=>{
        this.countRate = index+1;
        console.log(value);
        console.log(index);
      })
      
      
    },

    // changeIndex(){
    //   this.storageInfos.forEach((value, index)=>{
    //     this.countRate= index+1;
    //   })
    // }
    
  },
};
</script>

<style scoped>
.background {
  background-image: linear-gradient(
    to top,
    rgba(0, 132, 89, 1),
    rgba(0, 132, 89, 0.8)
  );
  background-size: cover;
  position: relative;
  height: auto;
  background-position-y: 40%;
  clip-path: polygon(0 0, 100% 0%, 100% 91%, 0 100%, 0 100%);
}
.trans_info_container {
  padding-top: 100px;
  margin-bottom: 30px;
}
.transport_list {
  background-color: #cce6de;
  font-size: 14px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column: 2/-1;
  padding: 15px;
}
.transport_information {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-left: auto;
  margin-right: auto;
}
.trans_info_item {
  margin-bottom: 5px;
}
.input_style {
  width: 250px;
  height: 25px;
  color: rgba(0, 132, 89, 1);
  border-color: rgba(0, 132, 89, 1);
  border-style: solid;
  outline: none;
  border-radius: 5px;
  box-shadow: 2px 2px 2px 0px rgba(0, 132, 89, 0.2);
}
.btn {
  grid-column: 1/-1;
  width: 250px;
  height: 35px;
  margin-right: auto;
  margin-left: auto;
  background-color: rgba(0, 132, 89, 1);
  color: white;
  font-size: 18px;
  padding-top: 8px;
  padding-bottom: 8px;
  border: none;
  border-radius: 5px;
  transition: all 0.3s linear;
}
.btn:hover,
.btn:active {
  background-color: #339d7a;
  cursor: pointer;
}

.table_section {
  padding-bottom: 80px;
  font-size: 14px;
}
</style>
