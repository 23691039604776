<template>
  <the-base-product-list-style>
    <template v-slot: product></template>
    <template v-slot:product_header>
      <h3>Эмульгаторы</h3>
    </template>
    <template v-slot:product_title>
      <h5>Эмульсионные базы</h5>
    </template>
    <template v-slot:product_list>
      <!-- Cetearyl alcohol, ceteareth-20 -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Cetearyl alcohol, ceteareth-20</h6>
        </template>
        <template v-slot:product_text>
          <p></p>
        </template>
      </the-base-product-item-style>
      <!-- Cetearyl alcohol, polysorbate 60, PEG-100 stearate, ceteareth-25 -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Cetearyl alcohol, polysorbate 60, PEG-100 stearate, ceteareth-25</h6>
        </template>
        <template v-slot:product_text>
          <p></p>
        </template>
      </the-base-product-item-style>
      <!-- Glyceryl stearate, ceteareth-20, ceteareth-20, ceteareth-12, cetearyl alcohol, cetyl palmitate -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Glyceryl stearate, ceteareth-20, ceteareth-20, ceteareth-12,
            cetearyl alcohol, cetyl palmitate</h6>
        </template>
        <template v-slot:product_text>
          <p></p>
        </template>
      </the-base-product-item-style>
      <!-- Glyceryl stearate, PEG-100 stearate -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Glyceryl stearate, PEG-100 stearate</h6>
        </template>
        <template v-slot:product_text>
          <p></p>
        </template>
      </the-base-product-item-style>
      <!-- PEG-6 caprylic/capric glycerides -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>PEG-6 caprylic/capric glycerides</h6>
        </template>
        <template v-slot:product_text>
          <p></p>
        </template>
      </the-base-product-item-style>
      <!-- PEG-40 hydrogenated castor oil -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>PEG-40 hydrogenated castor oi</h6>
        </template>
        <template v-slot:product_text>
          <p></p>
        </template>
      </the-base-product-item-style>
      <!-- Laureth-4 -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Laureth-4</h6>
        </template>
        <template v-slot:product_text>
          <p></p>
        </template>
      </the-base-product-item-style>
      <!-- Laureth-23 -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Laureth-23</h6>
        </template>
        <template v-slot:product_text>
          <p></p>
        </template>
      </the-base-product-item-style>
    </template>
  </the-base-product-list-style>
</template>