<template>
  <the-base-product-list-style>
    <template v-slot: product></template>
    <template v-slot:product_header>
      <h3>Активные ингредиенты</h3>
    </template>
    <template v-slot:product_title>
      <h5>Ферменты</h5>
    </template>
    <template v-slot:product_list>
      <!-- ACB Modified Pomegranate Enzyme PF -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>ACB Modified Pomegranate Enzyme PF</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Lactobacillus/Punica Granatum Fruit Ferment Extract &
            Leuconostoc/Radish Root Ferment Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- ACB Modified Pumpkin Enzyme PF -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>ACB Modified Pumpkin Enzyme PF</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Lactobacillus/Pumpkin Fruit Ferment Filtrate &
            Leuconostoc/Radish Root Ferment Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- ACB Modified Papaya Enzyme PF -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>ACB Modified Papaya Enzyme PF</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Lactobacillus/Papaya Fruit Ferment Extract &
            Leuconostoc/Radish Root Ferment Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- ABS Pap-Ango Enzyme PF -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>ABS Pap-Ango Enzyme PF</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Aqua & Carica Papaya (Papaya) Fruit Extract & Mangifera Indica
            (Mango) Fruit Extract & Leuconostoc/Radish Root Ferment Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- ACB Cranberry Enzyme PF -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>ACB Cranberry Enzyme PF</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Aqua & Vaccinium Macrocarpon (Cranberry) Fruit Extract &
            Leuconostoc/Radish Root Ferment Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- AC Sebum Control Enzyme PF -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>AC Sebum Control Enzyme PF</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Butylene Glycol & Aqua & Spiraea Ulmaria Extract &
            Lactobacillus Ferment
          </p>
        </template>
      </the-base-product-item-style>
      <!-- ACB Ox-Red SOD -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>ACB Ox-Red SOD</h6>
        </template>
        <template v-slot:product_text>
          <p>INCI: Superoxide Dismutase</p>
        </template>
      </the-base-product-item-style>
    </template>
  </the-base-product-list-style>
</template>