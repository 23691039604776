<template>
  <div>
    <div class="product">
      <slot name="product"></slot>
      <div class="product_header">
        <slot name="product_header"></slot>
      </div>
      <div class="product_header_description">
        <slot name="product_header_description"></slot>
      </div>
      <div class="product_title">
        <slot name="product_title"></slot>
      </div>
      <div class="product_title_descriprion">
        <slot name="product_title_description"></slot>
      </div>
    </div>
    <div class="product_list container">
      <slot name="product_list"></slot>
    </div>
  </div>
</template>
<style scoped>
.product {
  background-image: linear-gradient(
    to top,
    rgba(0, 132, 89, 1),
    rgba(0, 132, 89, 0.8)
  );
  background-size: cover;
  position: relative;
  height: 80vh;
  background-position-y: 40%;
  clip-path: polygon(0 0, 100% 0%, 100% 91%, 0 100%, 0 100%);
  color: white;
}
.product_header {
  padding-top: 120px;
  font-size: 4.2rem;
  text-align: center;
}
.product_header_description {
  font-size: 2rem;
  text-align: center;
  margin-top: 35px;
  width: 80vw;
  margin-left: auto;
  margin-right: auto;
}
.product_title {
  font-size: 3.6rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}
.product_title_descriprion{
  font-size: 2rem;
  text-align: center;
  margin-top: 35px;
  width: 80vw;
  margin-left: auto;
  margin-right: auto;
}
.product_list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  /* padding-bottom: 105px; */
  justify-content: center;
}

/* MEDIA QUERIES */
/* Media Queries (max-width: 1350px) */
@media (max-width: 1350px) {
  .product_header {
    font-size: 3.6rem;
  }
  
}
/* Media Queries (max-width: 1250px) */
@media (max-width: 1250px) {
  .product_header {
    font-size: 3.4rem;
  }
  
}
/* Media Queries (max-width: 1200px) */
@media (max-width: 1200px) {
  .product_header {
    font-size: 3.2rem;
  }
  
}
/* Media Queries (max-width: 998px) */
@media (max-width: 998px) {
  .product_header {
    font-size: 2.8rem;
  }
  .product_header_description {
    font-size: 1.8rem;
  }
  .product_title_description {
    font-size: 1.8rem;
  }
}

/* Media Queries (max-width: 747px) */
@media (max-width: 747px) {
  .product_header {
    font-size: 2.4rem;
  }
  .product_header_description {
    font-size: 1.6rem;
    margin-top: 25px;
  }
  .product_title_description {
    font-size: 1.6rem;
    margin-top: 25px;
  }
  .product_list {
    grid-template-columns: 1fr;
  }
}
/* Media Queries (max-width: 500px) */
@media (max-width: 500px) {
  .product_header {
    font-size: 2rem;
  }
  .product_header_description {
    font-size: 1.4rem;
    margin-top: 20px;
  }
  .product_title_description {
    font-size: 1.4rem;
    margin-top: 20px;
  }
}
/* Media Queries (max-width: 350px) */
@media (max-width: 350px) {
  .product_header {
    font-size: 1,8rem;
  }
  .product_header_description {
    font-size: 1.2rem;
    margin-top: 10px;
  }
  .product_title_description {
    font-size: 1.2rem;
    margin-top: 10px;
  }
}

</style>
