<template>
  <the-base-product-list-style>
    <template v-slot: product></template>
    <template v-slot:product_header>
      <h3></h3>
    </template>
    <template v-slot:product_title>
      <h5>Производство полиэфирных волокон</h5>
    </template>
    <template v-slot:product_list>
      
      <the-base-product-item-style v-for="PolyesterFiberObject in PolyesterFiberObjects" v-bind:key="PolyesterFiberObject.id">
        <template v-slot:product_subtitle>
          <h6>{{PolyesterFiberObject.name}}</h6>
          <a    
            v-show="PolyesterFiberObject.url"       
            :href="PolyesterFiberObject.url"
            :download="PolyesterFiberObject.name"
            class="button_style">          
            Скачать СГР
          </a>
        </template>
        <template v-slot:product_text>
          <p>
            {{PolyesterFiberObject.description}}
          </p>
        </template>
      </the-base-product-item-style>
    </template>
  </the-base-product-list-style>
</template>





<script>
export default {
  data() {
    return {
      PolyesterFiberObjects: [
        {
          id: 1,
          name: "Avifil PE 32",
          chemicalName: "",
          description:
            "Прядильная препарация  для высокоскоростного формования, совмещенного с вытягиванием для производства  нитей типа HM.",
          url: ("sgr/sgr_minerva_avifil_pe_32.pdf"),
        },
        {
          id: 2,
          name: "Avifil HT 906",
          chemicalName: "",
          description: "Прядильная препарация для высокоскоростного формования, совмещенного с вытягиванием для производства нитей типа HT, LS.",
          url: ("sgr/sgr_minerva_avifil_ht_906.pdf"),
        },
        {
          id: 3,
          name: "Avifil PE 17",
          chemicalName: "",
          description: "Финишная препарация для производства полиэфирных технических нитей",
          url: "",
        },
        {
          id: 4,
          name: "Avifil GEN",
          chemicalName: "",
          description: "Финишная препарация для производства полиэфирных нитей типа SDY, FDY и FOY процесса.",
          url: ("sgr/sgr_minerva_avifil_gen.pdf"),
        },
        {
          id: 5,
          name: "Novafil 941 НМ",
          chemicalName: "",
          description: "Прядильная препарация для производства нити полиэфирной HMLS  технического назначения.",
          url: ("sgr/sgr_novaresse_novafil_941_hm.pdf"),
        },
        {
          id: 6,
          name: "Novafil NPC",
          chemicalName: "",
          description: "Cмесь лубриканта и антистатика для обработки полиэфирных штапельных волокон.",
          url: ("sgr/sgr_novaresse_novafil_npc.pdf"),
        },
        {
          id: 7,
          name: "Пентаэритритол полиглицидиловый эфир",
          chemicalName: "",
          description: "",
          url: "",
        },
        {
          id: 8,
          name: "Пиперазин",
          chemicalName: "",
          description: "",
          url: "",
        },
      ],
    };
  },
};
</script>

<style>
.button_style {
  font-size: 14px;
  background-color: rgba(0, 132, 89, 1);
  color: white;
  border: none;
  padding: 5px 10px 5px 10px;
  border-radius: 6px 0px 6px 0px;
  
}
a {
  outline: none;
  text-decoration: none;
  color: black;
}
</style>