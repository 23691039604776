<template>
  <div class="background_map">
    <div class="map">
      <div class="map_img">
        <img loading="lazy" src="../assets/bela.png" alt="Map of Belarus" />
      </div>
      <div class="map_item">
        <div class="map_title">Месторасположение</div>
        <div class="map_text">
          <p>Компания ГринХеми расположена</p>
          <p>в  городе Минск, Республика Беларусь</p>
          <p>и обеспечивает быструю</p>
          <p>и надежную поставку товаров</p>
          <p>для удовлетворения ваших потребностей.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.background_map {
  background-image: linear-gradient(
    to top,
    rgba(0, 132, 89, 0.8),
    rgba(0, 132, 89, 1)
  );
  clip-path: polygon(100% 0, 100% 0, 100% 99%, 0 100%, 0 19%);
}
.map {
  max-width: 75vw;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: white;
  padding-top: 100px;
}
.map_img {
  position: relative;
  opacity: 0.2;
}
.map_img img {
  width: 550px;
  width: 550px;
}
.map_img::after {
  content: url("../assets/svgMapPoint.svg");
  position: absolute;
  width: 45px;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.map_item {
  justify-self: left;
  align-self: center;
  background-color: transparent;
}
.map_title {
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 25px;
  text-align: end;
  text-decoration: underline solid white;
}
.map_text {
  font-size: 24px;
  font-weight: 300;
}
.map_text p {
  text-align: end;
}
/* MEDIA QUERIES */

/* Media Queries (max-width: 1350px) */
@media (max-width: 1350px) {
  .map_title {
    font-size: 3.4rem;
  }
  .map_text {
    font-size: 2.2rem;
  }
  /* Media Queries (max-width: 1250px) */
  @media (max-width: 1250px) {
    .map_img img {
      width: 500px;
      height: 500px;
    }
    .map_title {
      font-size: 3rem;
    }
    .map_text {
      font-size: 2rem;
    }
    /* Media Queries (max-width: 1200px) */
    @media (max-width: 1200px) {
      .map_img img {
        width: 450px;
        height: 450px;
      }
      .map_item {
        align-content: center;
        justify-items: center;
      }
      .map_title {
        font-size: 2.8rem;
      }
      .map_text {
        font-size: 1.8rem;
      }
    }
    /* Media Queries (max-width: 998px) */
    @media (max-width: 998px) {
      .map {
        flex-direction: column;
        max-width: 100vw;
        height: auto;
        align-self: center;
      }
      .map_img {
        height: 400px;
      }
      .map_img img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 400px;
        height: 400px;
      }
      .map_img::after {
        width: 35px;
      }
      .map_item {
        margin-bottom: 15px;
      }
      .map_title {
        font-size: 2.4rem;
        text-align: center;
        margin-bottom: 15px;
      }
      .map_text {
        font-size: 1.8rem;
      }
      .map_text p {
        text-align: center;
      }
    }
    /* Media Queries (max-width: 747px) */
    @media (max-width: 747px) {
      .map_img {
        height: 300px;
      }
      .map_img img {
        width: 250px;
        height: 250px;
      }
      .map_img::after {
        width: 25px;
      }
      .map_title {
        font-size: 2rem;
      }
      .map_text {
        font-size: 1.8rem;
      }
    }
    /* Media Queries (max-width: 500px) */
    @media (max-width: 500px) {
      .map_img {
        height: 200px;
      }
      .map_img img {
        width: 200px;
        height: 200px;
      }
      .map_img::after {
        width: 20px;
      }
      .map_title {
        font-size: 1.8rem;
      }
      .map_text {
        font-size: 1.6rem;
      }
    }
  }
}
</style>