<template>
  <div class="background_company polygon">
    <div class="company">
      <h1 class="company_title">ООО "ГринХеми"</h1>
      <p class="company_text">
        Компания, которую возглавляет кандидат химических наук, основана осенью
        2014 года.
      </p>

      <p>
        Основное преимущество компании – глубокие знания в области химии и
        современных технических процессах, а также большой опыт продаж.
      </p>
      <p>
        ГринХеми осуществляет поставку химического сырья для косметической,
        дорожной и индустриальной областей промышленности.
      </p>
      <p>
        Благодаря обширным контактам среди европейских и азиатских
        производителей химического сырья для различных индустрий промышленности,
        мы имеем возможность подобрать актуальный продукт, оказать техническую
        поддержку в разработке новых рецептур или замене сырья в существующих
        составах с целью снижения себестоимости продукции.
      </p>
      <p>
        Наша цель – формирование долговременных отношений с нашими клиентами с
        обеспечением качества химического сырья, технологических инноваций,
        высокого сервиса и снижение себестоимости.
      </p>
    </div>
  </div>
</template>

<style scoped>
.background_company {
  background-image: linear-gradient(
      to top,
      rgba(0, 132, 89, 1),
      rgba(0, 132, 89, 0.8)
    ),
    url(../assets/company.jpg);
  background-size: cover;
  position: relative;
  height: 110vh;
  background-position-y: 40%;
}
.polygon {
  clip-path: polygon(0 0, 100% 0%, 100% 91%, 0 100%, 0 100%);
}
.company {
  width: 75%;
  margin-right: auto;
  margin-left: auto;
}
.company_title {
  font-family: "Roboto", sans-serif;
  font-size: 3.5rem;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 1.1px;
  text-align: center;
  margin-bottom: 5rem;
  padding-top: 12rem;
  color: white;
}
p {
  font-family: "Roboto", sans-serif;
  font-size: 1.89rem;
  letter-spacing: 1px;
  line-height: 1.5;
  color: #ffffff;
  text-align: center;
  margin-bottom: 2.5rem;
  text-align: justify;
  text-indent: 30px;
}
/* Media Queries  for IntroSection */
/* Media Queries 998px */
@media (max-width: 998px) {
  .company {
    width: 95%;
  }
  .company_title {
    font-size: 2.8rem;
  }
  p {
    font-size: 1.6rem;
  }
}
</style>