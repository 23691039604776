<template>
  <the-base-product-list-style>
    <template v-slot: product></template>
    <template v-slot:product_header>
      <h3>Активные ингредиенты</h3>
    </template>
    <template v-slot:product_title>
      <h5>Протеины</h5>
    </template>
    <template v-slot:product_list>
      <!-- AC Phytocoll PF -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>AC Phytocoll PF</h6>
        </template>
        <template v-slot:product_text>
          <p>INCI: Yeast Extract & Leuconostoc/Radish Root Ferment Filtrate</p>
        </template>
      </the-base-product-item-style>
      <!-- ACB Pisum Sativum Peptide -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>ACB Pisum Sativum Peptide</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Pisum Sativum (Pea) Peptide & Leuconostoc/Radish Root Ferment
            Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- AC Vegan Kerazyme® Protect -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>AC Vegan Kerazyme® Protect</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Hydrolyzed Corn Protein & Hydrolyzed Wheat Protein &
            Hydrolyzed Soy Protein & Bambusa Arundinacea Leaf Extract & Trametes
            Versicolor Extract & Leuconostoc/Radish Root Ferment Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- AC Vegan Yogurt Hydrolysate SF -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>AC Vegan Yogurt Hydrolysate SF</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Aqua & Cocos Nucifera (Coconut) Fruit Extract & Lactobacillus
            Ferment
          </p>
        </template>
      </the-base-product-item-style>
      <!-- ProCutiGen® Bond -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>ProCutiGen® Bond</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Salvia Hispanica Seed Extract & Leuconostoc/Radish Root
            Ferment Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- ProCutiGen® Hold -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>ProCutiGen® Hold</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Phyllostachis Bambusoide Extract & Leuconostoc/Radish Root
            Ferment Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- ProCutiGen® Vegan Thermal Shield -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>ProCutiGen® Vegan Thermal Shield</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Saccharomyces Cerevisiae Extract & Leuconostoc/Radish Root
            Ferment Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- AC Rice Amino Acids PF -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>AC Rice Amino Acids PF</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Aqua & Rice Amino Acids & Leuconostoc/Radish Root Ferment
            Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- AC Plant Keratin PF -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>AC Plant Keratin PF</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Hydrolyzed Corn Protein & Hydrolyzed Wheat Protein &
            Hydrolyzed Soy Protein & Leuconostoc/Radish Root Ferment Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- AC Plant Keratin PET -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>AC Plant Keratin PET</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Hydrolyzed Corn Protein & Hydrolyzed Wheat Protein &
            Hydrolyzed Soy Protein/ Phenoxyethanol
          </p>
        </template>
      </the-base-product-item-style>
      <!-- AC Plant Keratin OS -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>AC Plant Keratin OS</h6>
        </template>
        <template v-slot:product_text>
          <p>INCI: AMP-Isostearoyl Wheat/Corn/Soy Amino Acidsl</p>
        </template>
      </the-base-product-item-style>
      <!-- ACB Quinoa Protein -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>ACB Quinoa Protein</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Aqua & Hydrolyzed Quinoa & Leuconostoc/Radish Root Ferment
            Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- AC Oat Hydrolysate OS -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>AC Oat Hydrolysate OS</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Cocoyl Hydrolyzed Oat Protein & Leuconostoc//Radish Root
            Ferment Filtrate
          </p>
        </template>
      </the-base-product-item-style>
    </template>
  </the-base-product-list-style>
</template>