import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import { createI18n } from "vue-i18n";
import App from "./App.vue";

import CosmeticsSection from "./components/Cosmetic/CosmeticsSection.vue";
import TheBioenzymes from "./components/Cosmetic/TheBioenzymes.vue";
import BotanicalExtracts from "./components/Cosmetic/BotanicalExtracts.vue";
import ComplexAssets from "./components/Cosmetic/ComplexAssets.vue";
import AssetsDeliverySystems from "./components/Cosmetic/AssetsDeliverySystem.vue";
import FunctionalAssets from "./components/Cosmetic/FunctionalAssets.vue";
import TheEnzymes from "./components/Cosmetic/TheEnzymes.vue";
import TheProteins from "./components/Cosmetic/TheProteins.vue";
import CosmeticEmollient from "./components/Cosmetic/CosmeticEmollient.vue";
import FermentOil from "./components/Cosmetic/FermentOil.vue";

import CosmeticEmulsifier from "./components/Cosmetic/CosmeticEmulsifier.vue";
import WhiteningIngredients from "./components/Cosmetic/WhiteningIngredients.vue";
import ThePeptides from "./components/Cosmetic/ThePeptides.vue";

import RoadSection from "./components/Road/RoadSection.vue";
import CationicEmulsifier from "./components/Road/CationicEmulsifier.vue";
import AnionicEmulsifier from "./components/Road/AnionicEmulsifier.vue";
import WarmAsphaltIngredients from "./components/Road/WarmAsphaltIngredients.vue";
import TheLatex from "./components/Road/TheLatex.vue";
import AdhesiveAdditives from "./components/Road/AdhesiveAdditives.vue";

import IndustrialSection from "./components/Industry/IndustrialSection.vue";
import ProductsForPolyesterFibers from "./components/Industry/ProductsForPolyesterFibers.vue";
import ProductsForPolyamideFibers from "./components/Industry/ProductsForPolyamideFibers.vue";
import ProductsForFiberglass from "./components/Industry/ProductsForFiberglass.vue";
import SurfactantsForIndustry from "./components/Industry/SurfactantsForIndustry.vue";

import TheIntroSection from "./components/TheIntroSection.vue";
import TheCarouselSection from "./components/TheCarouselSection.vue";

import TheCompanySection from "./components/TheCompanySection.vue";
import TheContactsSection from "./components/TheContactsSection.vue";
import HeptagonLayout from "./components/HeptagonLayout.vue";
import TheMap from "./components/TheMap.vue";

import TransportLogin from "./components/TransportValue/TransportLogin.vue";
import TransportInformation from "./components/TransportValue/TransportInformation.vue";

import TheBaseStyle from "./styles/TheBaseStyle.vue";
import TheBaseCardStyle from "./styles/TheBaseCardStyle.vue";
import TheBaseProductListStyle from "./styles/TheBaseProductListStyle.vue";
import TheBaseProductItemStyle from "./styles/TheBaseProductItemStyle.vue";

import PageNotFound from "./components/PageNotFound.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", component: TheIntroSection },
    { path: "/cosmetic", component: CosmeticsSection },
    { path: "/bioenzymes", component: TheBioenzymes },
    { path: "/botanicalExtracts", component: BotanicalExtracts },
    { path: "/complexAssets", component: ComplexAssets },
    { path: "/assetsDeliverySystems", component: AssetsDeliverySystems },
    { path: "/functionalAssets", component: FunctionalAssets },
    { path: "/enzymes", component: TheEnzymes },
    { path: "/proteins", component: TheProteins },

    { path: "/cosmeticEmollient", component: CosmeticEmollient },
    { path: "/fermentOil", component: FermentOil },

    { path: "/cosmeticEmulsifier", component: CosmeticEmulsifier },
    { path: "/whiteningIngredients", component: WhiteningIngredients },
    { path: "/thePeptides", component: ThePeptides },

    { path: "/road", component: RoadSection },
    { path: "/cationicEmulsifier", component: CationicEmulsifier },
    { path: "/anionicEmulsifier", component: AnionicEmulsifier },
    { path: "/warmAsphaltIngredients", component: WarmAsphaltIngredients },
    { path: "/theLatex", component: TheLatex },
    { path: "/adhesiveAdditives", component: AdhesiveAdditives },

    { path: "/industrial", component: IndustrialSection },
    {
      path: "/productsForPolyesterFibers",
      component: ProductsForPolyesterFibers,
    },
    {
      path: "/productsForPolyamideFibers",
      component: ProductsForPolyamideFibers,
    },
    {
      path: "/productsForFiberglass",
      component: ProductsForFiberglass,
    },
    { path: "/surfactantsForIndustry", component: SurfactantsForIndustry },

    { path: "/company", component: TheCompanySection },
    { path: "/contacts", component: TheContactsSection },

    { path: "/transport", component: TransportLogin },
    { path: "/transportInfo", component: TransportInformation },
    {
      path: "/:catchAll(.*)",
      name: "NotFound",
      component: PageNotFound,
      meta: {
        requiresAuth: false,
      },
    },
  ],
});
const i18n = createI18n({
  locale: "Русский",
  messages: {
    Русский: {
      navigation: {
        navItem1: "Главная",
        navItem2: "Компоненты",
        navItem3: "Компания",
        navItem4: "Контакты",
        navItem5: "Косметическая отрасль",
        navItem6: "Дорожная отрасль",
        navItem7: "Индустриальная отрасль",
      },
      chooseLanguage: {
        chLang: "Выберите язык:",
      },
      contacts: {
        adressTitle: "Контакты",
        workTime: "Время работы:",
        workHours: "c 09:00 до 16:00 Понедельник-Пятница",
        adressLegal: "Юридический адрес:",
        adressPost: "Почтовый адрес:",
        nameCountry: "Республика Беларусь",
        indexCity: "220113 Минск",
        localAdress: "Логойский тракт, 15-4, офис 313",
        phone: "Телефон",
        fax: "Факс",
        eMail: "Электронная почта",
      },
    },
    English: {
      navigation: {
        navItem1: "Home",
        navItem2: "Components",
        navItem3: "Company",
        navItem4: "Contacts",
        navItem5: "Cosmetic industry",
        navItem6: "Road section",
        navItem7: "Industrial section",
      },
      contacts: {
        adressTitle: "Contacts",
        workTime: "Working hours",
        workHours: "from 09:00 to 16:00 Monday-Friday",
        adressLegal: "Legal address:",
        adressPost: "Post address:",
        nameCountry: "Republic of Belarus",
        indexCity: "220113 Minsk",
        localAdress: "Logoisk tract, 15-4, office 313",
        phone: "Phone",
        fax: "Fax",
        eMail: "Email",
      },
      chooseLanguage: {
        chLang: "Choose language:",
      },
    },
  },
});

const app = createApp(App);
app.use(router);
app.use(i18n);

app.component("CosmeticsSection", CosmeticsSection);
app.component("TheBioenzymes", TheBioenzymes);
app.component("BotanicalExtracts", BotanicalExtracts);
app.component("ComplexAssets", ComplexAssets);
app.component("AssetsDeliverySystem", AssetsDeliverySystems);
app.component("FunctionalAssets", FunctionalAssets);
app.component("TheEnzymes", TheEnzymes);
app.component("TheProteins", TheProteins);
app.component("CosmeticEmollient", CosmeticEmollient);
app.component("CosmeticEmulsifier", CosmeticEmulsifier);
app.component("WhiteningIngredients", WhiteningIngredients);
app.component("ThePeptides", ThePeptides);

app.component("RoadSection", RoadSection);
app.component("CationicEmulsifier", CationicEmulsifier);
app.component("AnionicEmulsifier", AnionicEmulsifier);
app.component("WarmAsphaltIngredients", WarmAsphaltIngredients);
app.component("TheLatex", TheLatex);
app.component("AdhesiveAdditives", AdhesiveAdditives);

app.component("IndustrialSection", IndustrialSection);
app.component("ProductsForPolyesterFibers", ProductsForPolyesterFibers);
app.component("ProductsForPolyamideFibers", ProductsForPolyamideFibers);
app.component("ProductsForFiberglass", ProductsForFiberglass);
app.component("SurfactantsForIndustry", SurfactantsForIndustry);

app.component("HeptagonLayout", HeptagonLayout);

app.component("TheIntroSection", TheIntroSection);
app.component("TheCarouselSection", TheCarouselSection);
app.component("TheCompanySection", TheCompanySection);
app.component("TheContactsSection", TheContactsSection);
app.component("TheMap", TheMap);
app.component("TransportLogin", TransportLogin);
app.component("TransportInformation", TransportInformation);
app.component("PageNotFound", PageNotFound);

app.component("TheBaseStyle", TheBaseStyle);
app.component("TheBaseCardStyle", TheBaseCardStyle);
app.component("TheBaseProductListStyle", TheBaseProductListStyle);
app.component("TheBaseProductItemStyle", TheBaseProductItemStyle);

app.mount("#app");
