<template>
  <the-base-product-list-style>
    <template v-slot: product></template>
    <template v-slot:product_header>
      <!-- <h3>Эмульгатор для дорог</h3> -->
    </template>
    <template v-slot:product_title>
      <h5>Анионный эмульгатор</h5>
    </template>
    <template v-slot:product_title_description>
      <!-- <p>
         Эмульгатор для битумных эмульсий - это вяжущий материал, представляющий
        собой однородную гомогенизированную смесь битума и воды, получаемую в
        диспергирующих устройствах (коллоидных мельницах) в присутствии
        поверхностно-активных веществ, обеспечивающих стабильность смеси.
      </p> -->
    </template>
    <template v-slot:product_list>
      <!-- Asfier A-50 -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>
            Asfier A-50<a
              class="download"
              href="tds/asfier A 50eng.pdf"
              download="Asfier A-50.pdf"
            >
              <svg
                class="product_tds"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 64 64"
                width="30px"
                height="30px"
                fill="white"
              >
                <path
                  d="M 23.65625 4 C 22.320313 4
     21.066406 4.519531 20.121094 5.464844 L 11.464844 14.121094 C 10.519531
      15.066406 10 16.320313 10 17.65625 L 10 57 C 10 58.652344 11.347656 60
       13 60 L 53 60 C 54.652344 60 56 58.652344 56 57 L 56 7 C 56 5.347656
        54.652344 4 53 4 Z M 24 6 L 53 6 C 53.550781 6 54 6.449219 54 7 L 54
         57 C 54 57.550781 53.550781 58 53 58 L 13 58 C 12.449219 58 12 57.550781
          12 57 L 12 18 L 21 18 C 22.652344 18 24 16.652344 24 15 Z M 22 6.5 L 22
           15 C 22 15.550781 21.550781 16 21 16 L 12.5 16 C 12.605469 15.835938
            12.734375 15.679688 12.878906 15.535156 L 21.535156 6.878906 C 21.679688
             6.738281 21.835938 6.613281 22 6.5 Z M 21 22 C 20.449219 22 20 22.449219
              20 23 C 20 23.550781 20.449219 24 21 24 L 37 24 C 37.550781 24 38 23.550781
               38 23 C 38 22.449219 37.550781 22 37 22 Z M 41 22 C 40.449219 22 40 22.449219
                40 23 C 40 23.550781 40.449219 24 41 24 L 45 24 C 45.550781 24 46 23.550781 
                46 23 C 46 22.449219 45.550781 22 45 22 Z M 21 26 C 20.449219 26 20 26.449219
                 20 27 C 20 27.550781 20.449219 28 21 28 L 41 28 C 41.550781 28 42 27.550781
                  42 27 C 42 26.449219 41.550781 26 41 26 Z M 21 32 C 20.449219 32 20 32.449219 
                  20 33 C 20 33.550781 20.449219 34 21 34 L 43 34 C 43.550781 34 44 33.550781 44
                   33 C 44 32.449219 43.550781 32 43 32 Z M 21 36 C 20.449219 36 20 36.449219 20 
                   37 C 20 37.550781 20.449219 38 21 38 L 33 38 C 33.550781 38 34 37.550781 34 37 
                   C 34 36.449219 33.550781 36 33 36 Z M 15 50 C 14.449219 50 14 50.449219 14 51
                    L 14 53 C 14 53.550781 14.449219 54 15 54 C 15.550781 54 16 53.550781 16 53 L 
                    16 51 C 16 50.449219 15.550781 50 15 50 Z M 20 50 C 19.449219 50 19 50.449219
                     19 51 L 19 53 C 19 53.550781 19.449219 54 20 54 C 20.550781 54 21 53.550781 
                     21 53 L 21 51 C 21 50.449219 20.550781 50 20 50 Z M 25 50 C 24.449219 50 24 50.449219 24 51 L 24 53 C 24 53.550781 24.449219 54 25 54 C 25.550781 54 26 53.550781 26 53 L 26 51 C 26 50.449219 25.550781 50 25 50 Z M 30 50 C 29.449219 50 29 50.449219 29 51 L 29 53 C 29 53.550781 29.449219 54 30 54 C 30.550781 54 31 53.550781 31 53 L 31 51 C 31 50.449219 30.550781 50 30 50 Z M 35 50 C 34.449219 50 34 50.449219 34 51 L 34 53 C 34 53.550781 34.449219 54 35 54 C 35.550781 54 36 53.550781 36 53 L 36 51 C 36 50.449219 35.550781 50 35 50 Z M 40 50 C 39.449219 50 39 50.449219 39 51 L 39 53 C 39 53.550781 39.449219 54 40 54 C 40.550781 54 41 53.550781 41 53 L 41 51 C 41 50.449219 40.550781 50 40 50 Z M 45 50 C 44.449219 50 44 50.449219 44 51 L 44 53 C 44 53.550781 44.449219 54 45 54 C 45.550781 54 46 53.550781 46 53 L 46 51 C 46 50.449219 45.550781 50 45 50 Z M 50 50 C 49.449219 50 49 50.449219 49 51 L 49 53 C 49 53.550781 49.449219 54 50 54 C 50.550781 54 51 53.550781 51 53 L 51 51 C 51 50.449219 50.550781 50 50 50 Z"
                />
              </svg>
            </a>
          </h6>
        </template>
        <template v-slot:product_text>
          <p>Эмульгатор для анионных битумных эмульсий.</p>
        </template>
      </the-base-product-item-style>
    </template>
  </the-base-product-list-style>
</template>







<style scoped>
.product_tds {
  margin-bottom: -5px;
  fill: rgba(0, 132, 89, 1);
}
</style>
