<template>
  <the-base-product-list-style>
    <template v-slot: product></template>
    <template v-slot:product_header>
      <h3></h3>
    </template>
    <template v-slot:product_title>
      <h5>Производство полиамидных волокон</h5>
    </template>
    <template v-slot:product_list>
      <the-base-product-item-style
        v-for="PolyamideFiberObject in PolyamideFiberObjects"
        v-bind:key="PolyamideFiberObject.id"
      >
        <template v-slot:product_subtitle>
          <h6>{{ PolyamideFiberObject.name }}</h6>
          <a
            :href="PolyamideFiberObject.url"
            :download="PolyamideFiberObject.name"
            class="button_style"
          >
            Скачать СГР
          </a>
        </template>

        <template v-slot:product_text>
          <p>
            {{ PolyamideFiberObject.description }}
          </p>
        </template>
      </the-base-product-item-style>
    </template>
  </the-base-product-list-style>
</template>





<script>
export default {
  data() {
    return {
      PolyamideFiberObjects: [
        {
          id: 1,
          name: "Avifil PA RDC",
          chemicalName: "",
          description:
            "Финишная препарация для производства полиамидных нитей  BCF Heat-Set",
          url: "sgr/sgr_minerva_avifil_pa_rdc.pdf",
        },
        {
          id: 2,
          name: "Novafil ANF",
          chemicalName: "",
          description:
            "Финишная препарация для производства изноустойчивых кордных полиамидных нитей ",
          url: "sgr/sgr_novaresse_novafil_anf.pdf",
        },
      ],
    };
  },
};
</script>

<style>
.button_style {
  font-size: 14px;
  background-color: rgba(0, 132, 89, 1);
  color: white;
  border: none;
  padding: 5px 10px 5px 10px;
  border-radius: 6px 0px 6px 0px;
  
}
a {
  outline: none;
  text-decoration: none;
  color: black;
}
</style>