<template>
  <div class="transport_info">
    <div class="transport_box">
      <!-- Authorization Transport -->
      <div v-if="authorizationValue === true" class="trans_intro">
        <h3>Авторизация в системе GreenChemi</h3>
        <div class="trans_intro_item">
          <p class="trans_intro_text">Логин</p>
          <input
            v-model="loginValue"
            class="trans_intro_input"
            type="text"
            name="login"
            id=""
          />
        </div>
        <div class="trans_intro_item">
          <p class="trans_intro_text">Пароль</p>
          <input
            v-model="passwordValue"
            class="trans_intro_input"
            type="text"
            name="password"
            id=""
          />
        </div>
        <router-link
          v-on:click="authorizationInfo"
          v-on:keyup.enter="authorizationInfo"
          to="/transportInfo"
          ><button class="trans_intro_btn">Войти</button></router-link
        >
        <router-view></router-view>
      </div>
      <!-- <transport-information v-if="authorizationValue"></transport-information> -->
    </div>
  </div>
</template>
  <script>
export default {
  data() {
    return {
      loginValue: "",
      passwordValue: "",
      login: "admin",
      password: "1111",
      authorizationValue: true,
    };
  },
  methods: {
    authorizationInfo() {
      if (
        this.loginValue === this.login &&
        this.password === this.passwordValue
      ) {
        this.authorizationValue = true;
        console.log(this.authorizationValue);
      }
    },
  },
};
</script>
  



<style scoped>
/* Authorization Transport */
.transport_info {
  background-image: linear-gradient(
    to top,
    rgba(0, 132, 89, 1),
    rgba(0, 132, 89, 0.8)
  );
  background-size: cover;
  position: relative;
  height: 110vh;
  background-position-y: 75%;
  clip-path: polygon(0 0, 100% 0%, 100% 91%, 0 100%, 0 100%);
  position: relative;
}
.transport_box {
  width: 500px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 10px 9px 8px -5px rgba(9, 9, 9, 0.2);
}
.trans_intro {
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.trans_intro h3 {
  font-size: 22px;
  text-align: center;
  margin-bottom: 15px;
}
.trans_intro_item {
  display: flex;
  flex-direction: row;
  width: 400px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  justify-content: space-between;
}
.trans_intro_text {
  padding-right: 20px;
  font-size: 18px;
}
.trans_intro_input {
  border-color: rgba(0, 132, 89, 1);
  width: 250px;
}
.trans_intro_btn {
  width: 200px;
  align-self: center;
  background-color: rgba(0, 132, 89, 1);
  color: white;
  font-size: 18px;
  padding-top: 8px;
  padding-bottom: 8px;
  border: none;
  border-radius: 3px;
  transition: all 0.3s linear;
  
}
.trans_intro_btn:hover,
.trans_intro_btn:active {
  background-color: #339d7a;
  cursor: pointer;
}
</style>