<template>
  <!-- Base Style For Components Page -->
  <the-base-style>
    <template v-slot:background>
      <div class="background_road"></div>
    </template>
    <template v-slot:base_header>
      <h2>Дорожная отрасль</h2>
      
    </template>
    <template v-slot:base_list>
      <!-- Card for Emulsifier -->
      <the-base-card-style>
        <template v-slot:base_item></template>
        <template v-slot:base_icon>
          <!-- SVG for Road Emulsifier -->
          <svg
            class="svg_image"
            xmlns="http://www.w3.org/2000/svg"
            xml:space="preserve"
            width="18.75mm"
            height="18.75mm"
            version="1.1"
            style="
              shape-rendering: geometricPrecision;
              text-rendering: geometricPrecision;
              image-rendering: optimizeQuality;
              fill-rule: evenodd;
              clip-rule: evenodd;
            "
            viewBox="0 0 90.45 90.45"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xmlns:xodm="http://www.corel.com/coreldraw/odm/2003"
          >
            <g id="Слой_x0020_1">
              <metadata id="CorelCorpID_0Corel-Layer" />
              <g id="_2632372655536">
                <g>
                  <path
                    class="roadEmulsifierfil0"
                    d="M45.22 5.11c22.12,0 40.12,18 40.12,40.12 0,22.12 -18,40.12 -40.12,40.12 -22.12,0 -40.12,-18 -40.12,-40.12 0,-22.12 18,-40.12 40.12,-40.12l0 0zm0 -5.11c-24.98,0 -45.22,20.25 -45.22,45.22 0,24.98 20.25,45.22 45.22,45.22 24.98,0 45.22,-20.25 45.22,-45.22 0,-24.98 -20.25,-45.22 -45.22,-45.22l0 0z"
                  />
                </g>
              </g>
              <circle
                class="roadEmulsifierfil0"
                cx="72.36"
                cy="45.22"
                r="2.55"
              />
              <circle
                class="roadEmulsifierfil0"
                cx="71.26"
                cy="53.26"
                r="2.55"
              />
              <circle
                class="roadEmulsifierfil0"
                cx="67.65"
                cy="60.64"
                r="2.55"
              />
              <circle
                class="roadEmulsifierfil0"
                cx="61.88"
                cy="66.71"
                r="2.55"
              />
              <circle
                class="roadEmulsifierfil0"
                cx="54.29"
                cy="70.84"
                r="2.55"
              />
              <circle
                class="roadEmulsifierfil0"
                cx="45.23"
                cy="72.36"
                r="2.55"
              />
              <circle
                class="roadEmulsifierfil0"
                cx="18.09"
                cy="45.22"
                r="2.55"
              />
              <circle
                class="roadEmulsifierfil0"
                cx="19.19"
                cy="53.26"
                r="2.55"
              />
              <circle
                class="roadEmulsifierfil0"
                cx="22.81"
                cy="60.64"
                r="2.55"
              />
              <circle
                class="roadEmulsifierfil0"
                cx="28.58"
                cy="66.71"
                r="2.55"
              />
              <circle
                class="roadEmulsifierfil0"
                cx="36.17"
                cy="70.84"
                r="2.55"
              />
              <circle
                class="roadEmulsifierfil0"
                cx="71.25"
                cy="37.19"
                r="2.55"
              />
              <circle
                class="roadEmulsifierfil0"
                cx="67.64"
                cy="29.81"
                r="2.55"
              />
              <circle
                class="roadEmulsifierfil0"
                cx="61.87"
                cy="23.73"
                r="2.55"
              />
              <circle
                class="roadEmulsifierfil0"
                cx="54.28"
                cy="19.61"
                r="2.55"
              />
              <circle
                class="roadEmulsifierfil0"
                cx="45.22"
                cy="18.09"
                r="2.55"
              />
              <circle
                class="roadEmulsifierfil0"
                cx="18.09"
                cy="45.22"
                r="2.55"
              />
              <circle
                class="roadEmulsifierfil0"
                cx="19.19"
                cy="37.19"
                r="2.55"
              />
              <circle
                class="roadEmulsifierfil0"
                cx="22.81"
                cy="29.81"
                r="2.55"
              />
              <circle
                class="roadEmulsifierfil0"
                cx="28.58"
                cy="23.73"
                r="2.55"
              />
              <circle
                class="roadEmulsifierfil0"
                cx="36.17"
                cy="19.61"
                r="2.55"
              />
              <g id="_2632372659184">
                <path
                  class="roadEmulsifierfil1 roadEmulsifierstr0"
                  d="M44.54 31.65c0,-3.39 1.36,-3.39 1.36,-6.78 0,-3.39 -1.36,-3.39 -1.36,-6.78"
                />
              </g>
              <g id="_2632372659856">
                <path
                  class="roadEmulsifierfil1 roadEmulsifierstr0"
                  d="M44.55 72.33c0,-3.39 1.35,-3.39 1.35,-6.77 0,-3.39 -1.35,-3.39 -1.35,-6.77"
                />
              </g>
              <g id="_2632372662256">
                <path
                  class="roadEmulsifierfil1 roadEmulsifierstr0"
                  d="M72.37 45.9c-3.4,0 -3.4,-1.36 -6.79,-1.36 -3.4,0 -3.4,1.36 -6.79,1.36"
                />
              </g>
              <g id="_2632372663648">
                <path
                  class="roadEmulsifierfil1 roadEmulsifierstr0"
                  d="M31.66 45.9c-3.39,0 -3.39,-1.36 -6.78,-1.36 -3.39,0 -3.39,1.36 -6.78,1.36"
                />
              </g>
              <g id="_2632372662784">
                <path
                  class="roadEmulsifierfil1 roadEmulsifierstr0"
                  d="M53.74 71.05c-1.14,-3.21 0.07,-3.64 -1.08,-6.84 -1.14,-3.21 -2.35,-2.78 -3.49,-5.98"
                />
              </g>
              <g id="_2632372662448">
                <path
                  class="roadEmulsifierfil1 roadEmulsifierstr0"
                  d="M40.08 32.65c-1.14,-3.2 0.07,-3.63 -1.07,-6.83 -1.14,-3.2 -2.35,-2.77 -3.48,-5.97"
                />
              </g>
              <g id="_2632372663024">
                <path
                  class="roadEmulsifierfil1 roadEmulsifierstr0"
                  d="M61.45 67.04c-2.09,-2.69 -1.24,-3.35 -3.32,-6.05 -2.09,-2.69 -2.94,-2.03 -5.03,-4.73"
                />
              </g>
              <g id="_2632372633168">
                <path
                  class="roadEmulsifierfil1 roadEmulsifierstr0"
                  d="M36.49 34.83c-2.08,-2.69 -1.23,-3.35 -3.32,-6.04 -2.08,-2.69 -2.93,-2.03 -5.02,-4.72"
                />
              </g>
              <g id="_2632372633792">
                <path
                  class="roadEmulsifierfil1 roadEmulsifierstr0"
                  d="M67.33 61.1c-2.81,-1.93 -2.17,-2.86 -4.99,-4.79 -2.81,-1.93 -3.45,-1.01 -6.26,-2.94"
                />
              </g>
              <g id="_2632372633840">
                <path
                  class="roadEmulsifierfil1 roadEmulsifierstr0"
                  d="M33.72 38c-2.81,-1.93 -2.17,-2.86 -4.98,-4.79 -2.81,-1.93 -3.44,-1 -6.25,-2.94"
                />
              </g>
              <g id="_2632372633024">
                <path
                  class="roadEmulsifierfil1 roadEmulsifierstr0"
                  d="M71.07 53.89c-3.27,-1.01 -2.88,-2.26 -6.15,-3.27 -3.27,-1.01 -3.65,0.24 -6.92,-0.77"
                />
              </g>
              <g id="_2632372631872">
                <path
                  class="roadEmulsifierfil1 roadEmulsifierstr0"
                  d="M32.06 41.85c-3.26,-1.01 -2.88,-2.26 -6.14,-3.26 -3.26,-1.01 -3.65,0.24 -6.91,-0.77"
                />
              </g>
              <g id="_2632372634224">
                <path
                  class="roadEmulsifierfil1 roadEmulsifierstr0"
                  d="M71.66 37.83c-3.32,1.02 -3.7,-0.25 -7.02,0.77 -3.32,1.02 -2.93,2.29 -6.24,3.3"
                />
              </g>
              <g id="_2632372633600">
                <path
                  class="roadEmulsifierfil1 roadEmulsifierstr0"
                  d="M32.46 49.87c-3.27,1.01 -3.65,-0.24 -6.92,0.76 -3.27,1.01 -2.88,2.25 -6.15,3.26"
                />
              </g>
              <g id="_2632372632016">
                <path
                  class="roadEmulsifierfil1 roadEmulsifierstr0"
                  d="M67.97 30.28c-2.81,1.93 -3.45,1 -6.26,2.93 -2.81,1.93 -2.17,2.86 -4.99,4.79"
                />
              </g>
              <g id="_2632372644976">
                <path
                  class="roadEmulsifierfil1 roadEmulsifierstr0"
                  d="M34.36 53.38c-2.81,1.93 -3.44,1 -6.25,2.94 -2.81,1.93 -2.17,2.86 -4.98,4.79"
                />
              </g>
              <g id="_2632372645168">
                <path
                  class="roadEmulsifierfil1 roadEmulsifierstr0"
                  d="M62.29 24.07c-2.08,2.69 -2.93,2.03 -5.02,4.72 -2.08,2.69 -1.23,3.35 -3.32,6.04"
                />
              </g>
              <g id="_2632372645888">
                <path
                  class="roadEmulsifierfil1 roadEmulsifierstr0"
                  d="M37.34 56.27c-2.08,2.69 -2.94,2.03 -5.02,4.73 -2.08,2.69 -1.23,3.35 -3.32,6.05"
                />
              </g>
              <g id="_2632372645456">
                <path
                  class="roadEmulsifierfil1 roadEmulsifierstr0"
                  d="M54.89 19.82c-1.13,3.21 -2.34,2.78 -3.48,5.99 -1.13,3.21 0.08,3.63 -1.06,6.84"
                />
              </g>
              <g id="_2632372646464">
                <path
                  class="roadEmulsifierfil1 roadEmulsifierstr0"
                  d="M41.3 58.22c-1.13,3.21 -2.34,2.78 -3.48,5.99 -1.13,3.21 0.08,3.63 -1.06,6.84"
                />
              </g>
            </g>
          </svg>
        </template>
        <template v-slot:base_title>Эмульгаторы</template>
        <template v-slot:base_description>
          <router-link
            v-on:click="scrollToTop"
            to="/cationicEmulsifier"
            class="base_element_active"
          >
            <li>Катионные эмульгаторы</li>
          </router-link>
          <router-link
            v-on:click="scrollToTop"
            to="/anionicEmulsifier"
            class="base_element_active"
            ><li>Анионные эмульгаторы</li></router-link
          >
        </template>
      </the-base-card-style>
      <!-- Card for Warm Asphalt Ingredients -->
      <the-base-card-style>
        <template v-slot:base_item></template>
        <template v-slot:base_icon>
          <!-- SVG for Warm Asphalt Ingredients -->
          <svg
            class="svg_image"
            xmlns="http://www.w3.org/2000/svg"
            xml:space="preserve"
            width="18.75mm"
            height="18.75mm"
            version="1.1"
            style="
              shape-rendering: geometricPrecision;
              text-rendering: geometricPrecision;
              image-rendering: optimizeQuality;
              fill-rule: evenodd;
              clip-rule: evenodd;
            "
            viewBox="0 0 101.85 101.85"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xmlns:xodm="http://www.corel.com/coreldraw/odm/2003"
          >
            <g id="Слой_x0020_1">
              <metadata id="CorelCorpID_0Corel-Layer" />
              <g id="_2632377254928">
                <g>
                  <path
                    class="warmAsphaltfil0"
                    d="M50.93 5.75c24.91,0 45.18,20.27 45.18,45.18 0,24.91 -20.27,45.18 -45.18,45.18 -24.91,0 -45.18,-20.27 -45.18,-45.18 0,-24.91 20.27,-45.18 45.18,-45.18l0 0zm0 -5.75c-28.13,0 -50.93,22.8 -50.93,50.93 0,28.13 22.8,50.93 50.93,50.93 28.13,0 50.93,-22.8 50.93,-50.93 0,-28.13 -22.8,-50.93 -50.93,-50.93l0 0z"
                  />
                </g>
              </g>
              <path
                class="warmAsphaltfil1 warmAsphaltstr0"
                d="M58.13 42.99l-14.41 0c-8.04,2.94 -13.78,10.65 -13.78,19.71 0,11.59 9.39,20.98 20.98,20.98 11.59,0 20.98,-9.39 20.98,-20.98 -0,-9.06 -5.74,-16.77 -13.78,-19.71z"
              />
              <rect
                class="warmAsphaltfil1 warmAsphaltstr0"
                x="43.72"
                y="37.15"
                width="14.41"
                height="5.84"
              />
              <polygon
                class="warmAsphaltfil1 warmAsphaltstr0"
                points="50.93,18 46.78,37.15 55.08,37.15 "
              />
              <path
                class="warmAsphaltfil1 warmAsphaltstr0"
                d="M58.13 42.99l9.8 2.95c1.98,0.6 3.97,-0.89 3.97,-2.95l0 0c0,-2.07 -1.99,-3.55 -3.97,-2.95l-9.8 2.95z"
              />
              <line
                class="warmAsphaltfil1 warmAsphaltstr0"
                x1="33.57"
                y1="50.93"
                x2="68.28"
                y2="50.93"
              />
              <path
                class="warmAsphaltfil0"
                d="M33.31 51.3l35.23 0c0,0 4.59,6.05 3.31,12.91 -1.28,6.87 -5.38,19.46 -20.06,19.46 -14.67,0 -22.34,-12.34 -21.84,-20.34 0.56,-9.08 3.37,-12.03 3.37,-12.03z"
              />
            </g>
          </svg>
        </template>
        <template v-slot:base_title_active
          ><router-link v-on:click="scrollToTop" to="/warmAsphaltIngredients">
            Добавки для теплого асфальта
          </router-link></template
        >
        <template v-slot:base_description>
          <div class="base_element">
            <li>Danox 15</li>
          </div>
        </template>
      </the-base-card-style>
      <!-- Card for Latex -->
      <the-base-card-style>
        <template v-slot:base_item></template>
        <template v-slot:base_icon>
          <!-- SVG for Latex -->
          <svg
            class="svg_image"
            xmlns="http://www.w3.org/2000/svg"
            xml:space="preserve"
            width="18.75mm"
            height="18.75mm"
            version="1.1"
            style="
              shape-rendering: geometricPrecision;
              text-rendering: geometricPrecision;
              image-rendering: optimizeQuality;
              fill-rule: evenodd;
              clip-rule: evenodd;
            "
            viewBox="0 0 151.61 151.61"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xmlns:xodm="http://www.corel.com/coreldraw/odm/2003"
          >
            <g id="Слой_x0020_1">
              <metadata id="CorelCorpID_0Corel-Layer" />
              <g id="_2632821845168">
                <g>
                  <path
                    class="latexfil0"
                    d="M75.81 8.56c37.08,0 67.25,30.17 67.25,67.25 0,37.08 -30.17,67.25 -67.25,67.25 -37.08,0 -67.25,-30.17 -67.25,-67.25 0,-37.08 30.17,-67.25 67.25,-67.25l0 0zm0 -8.56c-41.87,0 -75.81,33.94 -75.81,75.81 0,41.87 33.94,75.81 75.81,75.81 41.87,0 75.81,-33.94 75.81,-75.81 0,-41.87 -33.94,-75.81 -75.81,-75.81l0 0z"
                  />
                </g>
              </g>
              <g id="_2632821845312">
                <g>
                  <path
                    class="latexfil1"
                    d="M19.63 81.87l10.53 -0.66c0.23,1.71 0.69,3.01 1.39,3.91 1.14,1.45 2.77,2.17 4.89,2.17 1.58,0 2.8,-0.37 3.65,-1.11 0.86,-0.74 1.28,-1.6 1.28,-2.58 0,-0.93 -0.41,-1.76 -1.22,-2.49 -0.82,-0.73 -2.7,-1.42 -5.67,-2.08 -4.85,-1.09 -8.32,-2.54 -10.38,-4.35 -2.09,-1.81 -3.13,-4.11 -3.13,-6.91 0,-1.84 0.53,-3.58 1.6,-5.22 1.06,-1.64 2.67,-2.92 4.81,-3.86 2.15,-0.94 5.08,-1.4 8.81,-1.4 4.58,0 8.07,0.85 10.47,2.55 2.4,1.7 3.83,4.41 4.28,8.12l-10.43 0.61c-0.28,-1.61 -0.86,-2.78 -1.75,-3.52 -0.88,-0.73 -2.11,-1.1 -3.67,-1.1 -1.28,0 -2.26,0.27 -2.91,0.82 -0.65,0.54 -0.98,1.21 -0.98,1.99 0,0.57 0.27,1.08 0.81,1.54 0.52,0.47 1.76,0.91 3.71,1.32 4.84,1.04 8.3,2.1 10.4,3.16 2.09,1.07 3.62,2.39 4.56,3.97 0.96,1.58 1.43,3.35 1.43,5.3 0,2.3 -0.63,4.41 -1.91,6.35 -1.27,1.94 -3.04,3.41 -5.33,4.41 -2.28,1 -5.15,1.5 -8.62,1.5 -6.09,0 -10.31,-1.17 -12.66,-3.52 -2.34,-2.34 -3.67,-5.32 -3.98,-8.94z"
                  />
                  <path
                    class="latexfil1"
                    d="M57.72 57.9l20.72 0c3.45,0 6.1,0.85 7.95,2.56 1.85,1.71 2.78,3.83 2.78,6.35 0,2.12 -0.66,3.93 -1.98,5.45 -0.88,1.01 -2.16,1.81 -3.86,2.39 2.58,0.62 4.47,1.68 5.68,3.19 1.21,1.51 1.82,3.4 1.82,5.68 0,1.86 -0.43,3.53 -1.29,5.01 -0.87,1.48 -2.04,2.66 -3.54,3.52 -0.93,0.54 -2.33,0.93 -4.2,1.17 -2.49,0.33 -4.14,0.49 -4.96,0.49l-19.1 0 0 -35.82zm11.17 14.05l4.81 0c1.72,0 2.93,-0.3 3.6,-0.89 0.68,-0.59 1.02,-1.45 1.02,-2.58 0,-1.04 -0.34,-1.86 -1.02,-2.44 -0.67,-0.59 -1.85,-0.88 -3.53,-0.88l-4.89 0 0 6.79zm0 14.07l5.64 0c1.91,0 3.25,-0.34 4.03,-1.01 0.78,-0.68 1.17,-1.58 1.17,-2.72 0,-1.06 -0.39,-1.91 -1.16,-2.55 -0.77,-0.64 -2.13,-0.96 -4.06,-0.96l-5.62 -0 0 7.26z"
                  />
                  <path
                    class="latexfil1"
                    d="M96.76 93.71l0 -35.82 18.44 0c3.42,0 6.03,0.29 7.84,0.88 1.81,0.59 3.26,1.67 4.37,3.26 1.11,1.59 1.66,3.52 1.66,5.8 0,1.99 -0.42,3.7 -1.27,5.14 -0.85,1.44 -2.01,2.61 -3.5,3.51 -0.94,0.57 -2.24,1.04 -3.88,1.42 1.32,0.44 2.28,0.88 2.88,1.32 0.41,0.3 1,0.92 1.77,1.88 0.77,0.96 1.29,1.71 1.55,2.23l5.36 10.38 -12.5 0 -5.92 -10.95c-0.75,-1.42 -1.42,-2.34 -2,-2.76 -0.8,-0.55 -1.7,-0.83 -2.71,-0.83l-0.98 0 0 14.54 -11.12 0zm11.12 -21.3l4.67 0c0.5,0 1.48,-0.16 2.93,-0.49 0.73,-0.15 1.33,-0.52 1.8,-1.12 0.46,-0.6 0.69,-1.29 0.69,-2.08 0,-1.16 -0.37,-2.04 -1.1,-2.66 -0.73,-0.62 -2.11,-0.93 -4.13,-0.93l-4.86 -0 0 7.28z"
                  />
                </g>
                <g>
                  <path
                    class="latexfil0"
                    d="M36.19 57.29c-3.73,0 -6.66,0.47 -8.81,1.4 -2.14,0.94 -3.74,2.22 -4.81,3.86 -1.07,1.64 -1.6,3.38 -1.6,5.22 0,2.8 1.04,5.11 3.13,6.91 2.07,1.81 5.53,3.26 10.38,4.35 2.97,0.65 4.85,1.34 5.67,2.08 0.82,0.73 1.22,1.56 1.22,2.49 0,0.98 -0.43,1.84 -1.28,2.58 -0.85,0.74 -2.07,1.11 -3.65,1.11 -2.12,0 -3.75,-0.73 -4.89,-2.17 -0.7,-0.9 -1.16,-2.2 -1.39,-3.91l-10.53 0.66c0.31,3.62 1.64,6.6 3.98,8.94 2.34,2.35 6.56,3.52 12.66,3.52 3.47,0 6.34,-0.5 8.62,-1.5 2.28,-1 4.06,-2.47 5.33,-4.41 1.27,-1.94 1.91,-4.06 1.91,-6.35 0,-1.95 -0.47,-3.72 -1.43,-5.3 -0.95,-1.58 -2.47,-2.9 -4.56,-3.97 -2.1,-1.07 -5.56,-2.12 -10.4,-3.16 -1.95,-0.41 -3.19,-0.85 -3.71,-1.32 -0.54,-0.46 -0.81,-0.97 -0.81,-1.54 0,-0.78 0.33,-1.45 0.98,-1.99 0.65,-0.54 1.62,-0.82 2.91,-0.82 1.56,0 2.79,0.37 3.67,1.1 0.89,0.73 1.47,1.91 1.75,3.52l10.43 -0.61c-0.45,-3.71 -1.89,-6.42 -4.28,-8.12 -2.4,-1.71 -5.89,-2.56 -10.47,-2.56l0 0z"
                  />
                  <path
                    class="latexfil0"
                    d="M78.43 57.9l-20.72 0 0 35.82 19.1 0c0.82,0 2.47,-0.16 4.96,-0.49 1.87,-0.24 3.27,-0.63 4.2,-1.17 1.5,-0.86 2.68,-2.04 3.54,-3.52 0.86,-1.48 1.29,-3.15 1.29,-5.01 0,-2.28 -0.61,-4.17 -1.82,-5.68 -1.22,-1.51 -3.11,-2.57 -5.68,-3.19 1.7,-0.59 2.98,-1.39 3.86,-2.39 1.32,-1.51 1.98,-3.33 1.98,-5.45 0,-2.52 -0.92,-4.64 -2.78,-6.35 -1.85,-1.71 -4.5,-2.56 -7.95,-2.56l0 0zm-9.55 14.05l0 -6.79 4.89 -0c1.68,0 2.85,0.29 3.53,0.88 0.68,0.59 1.02,1.4 1.02,2.44 0,1.12 -0.34,1.98 -1.02,2.58 -0.67,0.59 -1.88,0.89 -3.6,0.89l-4.81 0 0 0zm0 14.07l0 -7.26 5.62 -0c1.94,0 3.29,0.32 4.06,0.96 0.78,0.64 1.16,1.5 1.16,2.55 0,1.14 -0.39,2.05 -1.17,2.72 -0.78,0.68 -2.12,1.01 -4.03,1.01l-5.64 0 0 0z"
                  />
                  <path
                    class="latexfil0"
                    d="M115.2 57.9l-18.44 0 0 35.82 11.12 0 0 -14.54 0.98 0c1.01,0 1.92,0.28 2.71,0.83 0.59,0.42 1.26,1.34 2,2.76l5.92 10.95 12.5 0 -5.36 -10.38c-0.26,-0.52 -0.78,-1.26 -1.55,-2.23 -0.77,-0.96 -1.36,-1.59 -1.77,-1.88 -0.61,-0.44 -1.56,-0.88 -2.88,-1.32 1.65,-0.37 2.94,-0.85 3.88,-1.42 1.49,-0.9 2.65,-2.06 3.5,-3.51 0.84,-1.44 1.27,-3.16 1.27,-5.14 0,-2.28 -0.55,-4.21 -1.66,-5.8 -1.11,-1.59 -2.56,-2.67 -4.37,-3.26 -1.81,-0.59 -4.42,-0.88 -7.84,-0.88l0 0zm-7.33 14.51l0 -7.28 4.86 -0c2.02,0 3.4,0.31 4.13,0.93 0.73,0.62 1.1,1.51 1.1,2.66 0,0.78 -0.23,1.47 -0.69,2.08 -0.47,0.6 -1.06,0.98 -1.8,1.12 -1.45,0.33 -2.43,0.49 -2.93,0.49l-4.67 0 0 0z"
                  />
                </g>
              </g>
            </g>
          </svg>
        </template>
        <template v-slot:base_title_active
          ><router-link v-on:click="scrollToTop" to="/theLatex"
            >Латекс</router-link
          ></template
        >
        <template v-slot:base_description>
          <div class="base_description">
            <div class="base_element">
              <li>Laitex B754</li>
            </div>
            <div class="base_element">
              <li>Laitex B733</li>
            </div>
            <div class="base_element">
              <li>Laitex B475</li>
            </div>
          </div>
        </template>
      </the-base-card-style>
      <!-- Card for Adhesive Additives -->
      <the-base-card-style>
        <template v-slot:base_item></template>
        <template v-slot:base_icon>
          <!-- SVG for Adhesive Additives -->
          <svg
            class="svg_image"
            xmlns="http://www.w3.org/2000/svg"
            xml:space="preserve"
            width="18.75mm"
            height="18.75mm"
            version="1.1"
            style="
              shape-rendering: geometricPrecision;
              text-rendering: geometricPrecision;
              image-rendering: optimizeQuality;
              fill-rule: evenodd;
              clip-rule: evenodd;
            "
            viewBox="0 0 96.66 96.66"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xmlns:xodm="http://www.corel.com/coreldraw/odm/2003"
          >
            <g id="Слой_x0020_1">
              <metadata id="CorelCorpID_0Corel-Layer" />
              <g id="_2632826116432">
                <g>
                  <path
                    class="adhesiveAddfil0"
                    d="M48.33 5.46c23.64,0 42.87,19.23 42.87,42.87 0,23.64 -19.23,42.87 -42.87,42.87 -23.64,0 -42.87,-19.23 -42.87,-42.87 0,-23.64 19.23,-42.87 42.87,-42.87l0 0zm0 -5.46c-26.69,0 -48.33,21.64 -48.33,48.33 0,26.69 21.64,48.33 48.33,48.33 26.69,0 48.33,-21.64 48.33,-48.33 0,-26.69 -21.64,-48.33 -48.33,-48.33l0 0z"
                  />
                </g>
              </g>
              <path
                class="adhesiveAddfil0"
                d="M69.75 57.45c-1.39,-5.83 -6.6,-15.93 -9.93,-21.91 -0.32,-0.58 -1.14,-0.58 -1.46,-0 -3.87,6.95 -10.29,19.48 -10.29,24.38 0,6.61 4.94,11.99 11.02,11.99 4.63,0 8.59,-3.13 10.22,-7.54 0.4,-1.57 0.63,-3.2 0.63,-4.88 -0,-0.62 -0.07,-1.3 -0.19,-2.03z"
              />
              <path
                class="adhesiveAddfil0"
                d="M45.7 59.92c0,-6.97 10.01,-24.64 11.92,-27.96 0.15,-0.27 0.15,-0.59 -0.01,-0.86l-3.59 -6.03c-0.33,-0.55 -1.13,-0.56 -1.46,-0.01 -4.43,7.26 -15.99,26.93 -15.99,34.63 0,9.29 7.53,16.85 16.8,16.85 3.56,0 6.88,-2.28 5.43,-2.28 -7.22,0 -13.1,-6.43 -13.1,-14.34z"
              />
              <path
                class="adhesiveAddfil0"
                d="M34.42 59.69c0,-9.1 13.92,-32 17.32,-37.46 0.17,-0.27 0.16,-0.62 -0.01,-0.89 -1.17,-1.87 -2.13,-3.35 -2.73,-4.27 -0.33,-0.51 -1.06,-0.51 -1.39,-0 -3.67,5.65 -20.64,32.29 -20.64,42.42 0,11.36 9.55,20.56 21.33,20.56 2.84,0 6.07,-1.26 5.05,-1.26 -10.45,0 -18.95,-8.57 -18.95,-19.1z"
              />
            </g>
          </svg>
        </template>
        <template v-slot:base_title_active
          ><router-link v-on:click="scrollToTop" to="/adhesiveAdditives">
            Адгезионная добавка
          </router-link></template
        >
        <template v-slot:base_description>
          <div class="base_element">
            <li>Ascote 50</li>
          </div>
        </template>
      </the-base-card-style>
    </template>
  </the-base-style>
</template>

<script>
export default {
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>


<style scoped>
.background_road {
  background-image: linear-gradient(
      to top,
      rgba(0, 132, 89, 1),
      rgba(0, 132, 89, 0.8)
    ),
    url(../../assets/road_section.jpg);
  background-size: cover;
  position: relative;
  height: 80vh;
  background-position-y: 40%;
  clip-path: polygon(0 0, 100% 0%, 100% 91%, 0 100%, 0 100%);
}

/* SVG Icon Style */
.svg_image {
  width: 100px;
  height: auto;
  border: 3px solid transparent;
  border-radius: 50%;
  background-color: white;
}
/* Style for Road Emulsifier */
.roadEmulsifierstr0 {
  stroke: #008259;
  stroke-width: 1.28;
  stroke-linecap: round;
  stroke-miterlimit: 22.9256;
}
.roadEmulsifierfil1 {
  fill: none;
  fill-rule: nonzero;
}
.roadEmulsifierfil0 {
  fill: #008259;
  fill-rule: nonzero;
}
/* Style for Warm Asphalt */
.warmAsphaltstr0 {
  stroke: #008259;
  stroke-width: 1.44;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 22.9256;
}
.warmAsphaltfil1 {
  fill: none;
  fill-rule: nonzero;
}
.warmAsphaltfil0 {
  fill: #008259;
  fill-rule: nonzero;
}
/* Style for Latex SVG */
.latexfil1 {
  fill: black;
  fill-rule: nonzero;
}
.latexfil0 {
  fill: #008259;
  fill-rule: nonzero;
}
/* Style for Adhesive Additives SVG */
.adhesiveAddfil0 {
  fill: #008259;
  fill-rule: nonzero;
}

/* Style for elements */
.base_element_active {
  list-style: none;
  text-decoration: none;
  color: rgba(0, 132, 89, 1);
  transition: all 0.2s ease-out;
}
.base_element_active:hover,
.base_element_active:active {
  color: #99cebd;
  cursor: pointer;
}
.base_element_active li {
  margin-bottom: 10px;
}
.base_element {
  font-size: 16px;
  list-style: none;
  text-decoration: none;
  color: rgba(0, 132, 89, 1);
  transition: all 0.2s ease-out;
}
.base_element li {
  margin-bottom: 3px;
}
a {
  text-decoration: none;
  color: #008459;
  transition: all 0.2s ease-out;
}
a:hover,
a:active {
  color: #99cebd;
  cursor: pointer;
}
</style>