<template>
  <the-base-product-list-style>
    <template v-slot: product></template>
    <template v-slot:product_header>
      <h3>Активные ингредиенты</h3>
    </template>
    <template v-slot:product_title>
      <h5>Биоферменты</h5>
    </template>
    <template v-slot:product_list>
      <!-- ACB Bio-Water Bamboo -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>ACB Bio-Water Bamboo</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Lactobacillus/Arundinaria Gigantea Ferment Filtrate &
            Leuconostoc/Radish Root Ferment Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- ACB Bio-Chelate 5PF -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>ACB Bio-Chelate 5PF</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Aqua & Saccharomyces/Zinc Ferment & Saccharomyces/Copper
            Ferment & Saccharomyces/Magnesium Ferment & Saccharomyces/Iron
            Ferment & Saccharomyces/Silicon Ferment & Leuconostoc/Radish Root
            Ferment Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- AC Dermal Respiratory Factor Advanced PF -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>AC Dermal Respiratory Factor Advanced PF</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Aqua & Saccharomyces Lysate Extract & Lactobacillus Ferment
          </p>
        </template>
      </the-base-product-item-style>
      <!-- ACB Yogurt Dermal Respiratory Factor PF -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>ACB Yogurt Dermal Respiratory Factor PF</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Lactobacillus Ferment Lysate Filtrate & Leuconostoc/Radish
            Root Ferment Filtrate & Populus Tremuloides Bark Extract
          </p>
        </template>
      </the-base-product-item-style>
      <!-- Probacillus Revive -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Probacillus Revive</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Lactobacillus Ferment Lysate & Leuconostoc/Radish Root Ferment
            Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- ACB Agave HSP -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>ACB Agave HSP</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Saccharomyces/Agave Americana Leaf Ferment Filtrate &
            Leuconostoc/Radish Root Ferment Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- AC Saccharomyces Ferment -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>AC Saccharomyces Ferment</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Saccharomyces Ferment & Leuconostoc/Radish Root Ferment
            Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- ACB Acerola Bioferment -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>ACB Acerola Bioferment</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Lactobacillus/Acerola Cherry Ferment & Leuconostoc/Radish Root
            Ferment Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- ACB Lemon Peel Extract G -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>ACB Lemon Peel Extract G</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Glyerin & Aqua & Lactobacillus/Lemon Peel Ferment Extract &
            Leuconostoc/Radish Root Ferment Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- ACB Olive Leaf Extract PF -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>ACB Olive Leaf Extract PF</h6>
        </template>
        <template v-slot:product_text>
          <p>INCI: Lactobacillus/Olive Leaf Ferment Extracte</p>
        </template>
      </the-base-product-item-style>
      <!-- ACB Purslane Bioferment SF -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>ACB Purslane Bioferment SF</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Lactobacillus/Portulaca Oleracea Ferment Extract &
            Lactobacillus
          </p>
        </template>
      </the-base-product-item-style>
      <!-- ACB Wasabi Extract PF -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>ACB Wasabi Extract PF</h6>
        </template>
        <template v-slot:product_text>
          <p>INCI: Lactobacillus/Wasabi Japonica Root Ferment Extract</p>
        </template>
      </the-base-product-item-style>
    </template>
  </the-base-product-list-style>
</template>

